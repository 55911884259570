import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

import LayoutLldd from '../components/LayoutLldd';
import HomeMenu from '../components/HomeMenu';
import Banner from '../components/Banner';
import HorizontalFormContact from '../components/HorizontalFormContact';

import "../assets/styles/components/HomeTestimonials.sass";

const HomeTestimonials = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`https://la-leyenda.com/2018/api/general-by-id/48`)
      .then(res => res.json())
      .then((res) => {
        setData(res);
      }
    )
  }, []);
  
  return(
    <LayoutLldd
      title={data.seo_title_es}
      description={data.seo_content_es}
    >
      <Banner/>
      <HorizontalFormContact />
      <HomeMenu />
      <div className="row contenido_min">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-xs-12">

              { ReactHtmlParser(data.content_es) }

            </div>
          </div>
        </div>

      </div>
    </LayoutLldd>
  )
}

export default HomeTestimonials;
