import React, { useEffect, useState} from 'react';
import ReactHtmlParser from 'react-html-parser';

import LayoutLldd from '../components/LayoutLldd';
import BannerInternal from '../components/BannerInternal';
import '../assets/styles/containers/SponsorsAndPartner.sass';

const SponsorsAndPartner = () => {
  const [categories, setCategories] = useState([]);
  const [sponsors, setSponsors] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/sponsors-and-partner`)
    .then(res => res.json())
    .then(
      (res) => {
        setCategories(res.categories);
        setSponsors(res.sponsors);
    })
  }, []);

  const handleClick = (id) => {
    resetAll();
    const parent = document.getElementById(`parent-${id}`);
    parent.classList.add('sponsors-partner__hg');

    const item = document.getElementById(`item-${id}`);
    item.style.display = 'block';
    parent.style.height = (parseInt(item.offsetHeight) + 90) + 'px';
  }

  const handleClose = () => {
    resetAll();
  }

  const resetAll = () => {
    const lista = document.getElementsByClassName('sponsors-partner__spo');
    for (let i = 0; i < lista.length; i++) {
      const element = lista[i];
      const items = element.getElementsByTagName('li');
      for (let a = 0; a < items.length; a++) {
        items[a].classList.remove('sponsors-partner__hg');
        items[a].style.height = 'auto';
      }
    }

    const listaDescription = document.getElementsByClassName('sponsors-partner__spo--descriptionspo');
    for (let i = 0; i < listaDescription.length; i++) {
      listaDescription[i].style.display = 'none';
    }
    return;
  }

  return(
    <LayoutLldd>
      <BannerInternal image="https://la-leyenda.com/2018/img/banners_contenido/sponsors.jpg" />
      <div className="row sponsors-partner">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-xs-12 text-center">
              <h1 className="sponsors-partner__title">PATROCINADORES Y COLABORADORES</h1>
            </div>
          </div>

          <div className="row no-gutters">
            <div className="col-xs-12">
              {categories.map(category => (
                <div key={category.id}>
                  <h2>{category.category_es}</h2>
                  <ul className="list-inline sponsors-partner__spo">
                    {sponsors.map(sponsor => (
                      <>
                        {sponsor.category_id === category.id &&
                          <li key={sponsor.id} id={`parent-${sponsor.id}`}>
                            <img
                              className="img-responsive center-block" 
                              src={`${process.env.REACT_APP_IMG_URL}/img/marcas_in/${sponsor.logo_mono}`} 
                              onMouseEnter={(e) => e.currentTarget.src = `${process.env.REACT_APP_IMG_URL}/img/marcas_in/${sponsor.logo}`} 
                              onMouseLeave={(e) => e.currentTarget.src = `${process.env.REACT_APP_IMG_URL}/img/marcas_in/${sponsor.logo_mono}`} 
                              onClick={() => handleClick(sponsor.id)}
                              border="0" 
                              alt="La Leyenda"
                            />
                            <div className="sponsors-partner__spo--descriptionspo" id={`item-${sponsor.id}`}>
                              <div className="row">
                                <div className="col-xs-12 text-right">
                                  <span onClick={() => handleClose()} className="sponsors-partner__cerrar">X</span>
                                </div>
                                <div className="col-xs-6 col-xs-offset-3 col-sm-5 col-sm-offset-0 col-md-5 col-md-offset-0 col-lg-5 col-lg-offset-0 text-center">
                                  <img className="img-responsive center-block" src={`${process.env.REACT_APP_IMG_URL}/img/sponsors/${sponsor.img}`} alt="" />
                                </div>
                                <div className="col-xs-12 col-sm-7 col-md-7 col-lg-7 text-justify">
                                  <h3>{sponsor.title_es}</h3>
                                  <p>{ReactHtmlParser(sponsor.description_es)}</p>
                                  <ul className="list-inline social-n">
                                    {sponsor.web ? 
                                      <li>
                                        <a href={sponsor.web} target="blank">
                                          <img 
                                            src={`${process.env.REACT_APP_IMG_URL}/img/redes/web_b.png`} alt=""
                                            onMouseEnter={(e) => e.currentTarget.src = `${process.env.REACT_APP_IMG_URL}/img/redes/web_b.png`}
                                            onMouseLeave={(e) => e.currentTarget.src = `${process.env.REACT_APP_IMG_URL}/img/redes/web_b.png`}
                                          />
                                        </a>
                                      </li> : '' 
                                    }
                                    {sponsor.facebook ? 
                                      <li>
                                        <a href={sponsor.facebook} target="blank">
                                          <img 
                                            src={`${process.env.REACT_APP_IMG_URL}/img/redes/facebook_b.png`} alt=""
                                            onMouseEnter={(e) => e.currentTarget.src = `${process.env.REACT_APP_IMG_URL}/img/redes/facebook_b_o.png`}
                                            onMouseLeave={(e) => e.currentTarget.src = `${process.env.REACT_APP_IMG_URL}/img/redes/facebook_b.png`}
                                          />
                                        </a>
                                      </li> : ''}
                                    {sponsor.twitter ? 
                                      <li>
                                        <a href={sponsor.twitter} target="blank">
                                          <img 
                                            src={`${process.env.REACT_APP_IMG_URL}/img/redes/twitter_b.png`} alt=""
                                            onMouseEnter={(e) => e.currentTarget.src = `${process.env.REACT_APP_IMG_URL}/img/redes/twitter_b_o.png`}
                                            onMouseLeave={(e) => e.currentTarget.src = `${process.env.REACT_APP_IMG_URL}/img/redes/twitter_b.png`}
                                          />
                                        </a>
                                      </li> : ''}
                                    {sponsor.instagram ? 
                                      <li>
                                        <a href={sponsor.instagram} target="blank">
                                          <img 
                                            src={`${process.env.REACT_APP_IMG_URL}/img/redes/instagram_b.png`} alt=""
                                            onMouseEnter={(e) => e.currentTarget.src = `${process.env.REACT_APP_IMG_URL}/img/redes/instagram_b_o.png`}
                                            onMouseLeave={(e) => e.currentTarget.src = `${process.env.REACT_APP_IMG_URL}/img/redes/instagram_b.png`}
                                          />
                                        </a>
                                      </li> : ''}
                                    {sponsor.youtube ? 
                                      <li>
                                        <a href={sponsor.youtube} target="blank">
                                          <img 
                                            src={`${process.env.REACT_APP_IMG_URL}/img/redes/youtube_b.png`} alt=""
                                            onMouseEnter={(e) => e.currentTarget.src = `${process.env.REACT_APP_IMG_URL}/img/redes/youtube_b_o.png`}
                                            onMouseLeave={(e) => e.currentTarget.src = `${process.env.REACT_APP_IMG_URL}/img/redes/youtube_b.png`}
                                          />
                                        </a>
                                      </li> : ''}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </li>
                        }
                      </>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </LayoutLldd>
  )
}

export default SponsorsAndPartner;
