import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import Layout from '../components/Layout';
import SpectatorStageMenu from '../components/SpectatorMenu';
import '../assets/styles/components/Spectators.sass'

class SpectatorStageOne extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            data: [],
        }
    }
    
    componentDidMount() {
        
        fetch('https://la-leyenda.com/2018/api/spectators/etapa1')
        .then(res => res.json())
        .then(
        (res) => {
            this.setState({
                isLoaded: true,
                data: res
            });
        },
        
        (error) => {
            this.setState({
                isLoaded: true,
                error
            });
        }
        )

        document.getElementById('spectatorEtapa1').classList.add('active')
        document.getElementById('spectators').classList.add('active')
    }

    componentWillUnmount() {
        document.getElementById('spectatorEtapa1').classList.remove('active')
        document.getElementById('spectators').classList.remove('active')
    }

    render() {
        return(
            <Layout>
                <SpectatorStageMenu/>
                <div className="row">
                    <div className="container" id="contenido">
                        <div className="row spectators">
                            <div className="col-xs-12">
                                {this.state.data.map((item) => (
                                    <div className="custom">
                                        <div>
                                            <h3>{item.title_es}</h3>
                                            { ReactHtmlParser(item.content_es) }
                                        </div>
                                        <div>
                                            <img className="img-responsive" src={`https://la-leyenda.com/2018/en/img/spectator/${item.picture}`} alt=""/>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default SpectatorStageOne;