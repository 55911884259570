import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import Modal from './Modal';

import '../assets/styles/components/Banner.sass';

class BannerInternal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      open: false,
      bannerHeight: 0,
    }

    this.modalStatus = this.modalStatus.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    const Banner = window.sessionStorage.getItem('banner_internal');
    if (Banner) {
      this.setState({
        data: JSON.parse(window.sessionStorage.getItem('banner_internal'))
      });
    } else {
      fetch(`https://la-leyenda.com/2018/api/banner-home/58`)
      .then(res => res.json())
      .then((res) => {
          this.setLocalStorage(res);
        }
      )
    }

    this.setState({
      bannerHeight: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) * 38 / 100
    })

    window.addEventListener('resize', this.handleResize);
    document.addEventListener("keydown", this.handleKeyDown, false);
  }

  componentDidUpdate() {
    const btnAction = document.getElementById('btnModalStatus');
    if (btnAction) {
      btnAction.addEventListener('click', this.modalStatus, false);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    document.removeEventListener("keydown", this.handleKeyDown, false);
    const btnModalStatus = document.getElementById('btnModalStatus');
    if (btnModalStatus) {
      document.getElementById('btnModalStatus').removeEventListener('click', this.modalStatus, false);
    }
  }

  setLocalStorage(banner) {
    try {
      window.sessionStorage.setItem('banner_internal', JSON.stringify(banner));
      this.setState({
        data: banner
      });
    } catch (error) {
      console.error(error);
    }
    return;
  }

  modalStatus() {
    this.setState({
      open: !this.state.open
    });
  }

  handleKeyDown(e) {
    if (e.keyCode === 27) {
      this.setState({
        open: false
      })
    }
  };

  handleResize() {
    this.setState({
      bannerHeight: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) * 38 / 100
    })
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col-xs-12 banner" style={{height: this.state.bannerHeight}}>
            <aside>
              {this.state.data.enlace_es ?
                <a href={this.state.data.enlace_es} title="La Leyenda del Dorado"> 
                  <img className="img-responsive" src={`https://la-leyenda.com/2018/en/img/banner/${this.state.data.foto_es}`} alt="" />
                </a> : 
                <img className="img-responsive" src={`https://la-leyenda.com/2018/en/img/banner/${this.state.data.foto_es}`} alt="" />
              }
              <div className="banner__actions">
                <figure>
                  <img src="https://la-leyenda.com/2018/en/img/la_leyenda_logo_2021.png" alt="La Leyenda del Dorado"/>
                </figure>

                { ReactHtmlParser(this.state.data.content_es) }

              </div>
            </aside>
          </div>
        </div>
  
        {this.state.open &&
          <Modal close={this.modalStatus}/>
        }
      </>
    ) 
  }
}

export default BannerInternal;
