import React from 'react';
import '../assets/styles/components/Footer.sass';

const Footer = () => (
  <div className="row Footer">
    <div className="Footer-legal">
      <p>
        <a className="Footer-link" href="mailto:info@la-leyenda.com">
          info@la-leyenda.com
        </a>
        - ©Copyright La Leyenda del Dorado - Todos los derechos reservados -
        <a className="Footer-link" target="_blank" href="https://la-leyenda.com/documents/privacy_policy.pdf">
          Política de privacidad
        </a>
        &
        <a className="Footer-link" target="_blank" href="https://la-leyenda.com/documents/terms_of_use.pdf">
          Terminos de uso
        </a>
      </p>
    </div>
    <div className="Footer-redes">
      <ul className="redes-menu">
        <li>
          <a href="https://www.facebook.com/laleyendadeldorado/?fref=ts" target="blank">
            <span className="redes-menu__fb"></span>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/laleyendamtb" target="blank">
            <span className="redes-menu__tw"></span>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/laleyendamtb/" target="blank">
            <span className="redes-menu__in"></span>
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UCY4v-WN-1st7I9OjU2ZZXVw" target="blank">
            <span className="redes-menu__yt"></span>
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/la-leyenda-del-dorado-mtb" target="blank">
            <span className="redes-menu__lk"></span>
          </a>
        </li>
      </ul>
    </div>
  </div>
);

export default Footer;
