import React from 'react';
import { NavLink } from 'react-router-dom';

import LayoutLldd from '../components/LayoutLldd';
import BannerInternal from '../components/BannerInternal';
import '../assets/styles/containers/Gallery.sass';

const Gallery = () => {

  return(
    <LayoutLldd>
      <BannerInternal image="https://la-leyenda.com/2018/en/img/banners_contenido/gallery.jpg" />
      <div className="row gallery">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-xs-12 text-center">
              <h1 className="gallery__title">GALERÍA</h1>
            </div>
          </div>

          <div className="row no-gutters">
            <div className="col-xs-12">
              <ul className="gallery__list">
                <li><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/2019.jpg`} alt="La leyenda 2019"/></li>
                <li><NavLink to={'/gallery/22/prologo_2019'}><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/2019_prologo.jpg`} alt="La Leyenda el Dorado"/><span></span></NavLink></li>
                <li><NavLink to={'/gallery/23/etapa1_2019'}><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/2019_etapa1.jpg`} alt="La Leyenda el Dorado"/><span></span></NavLink></li>
                <li><NavLink to={'/gallery/24/etapa2_2019'}><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/2019_etapa2.jpg`} alt="La Leyenda el Dorado"/><span></span></NavLink></li>
                <li><NavLink to={'/gallery/25/etapa3_2019'}><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/2019_etapa3.jpg`} alt="La Leyenda el Dorado"/><span></span></NavLink></li>
                <li><NavLink to={'/gallery/26/etapa4_2019'}><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/2019_etapa4.jpg`} alt="La Leyenda el Dorado"/><span></span></NavLink></li>
                <li><NavLink to={'/gallery/27/etapa5_2019'}><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/2019_etapa5.jpg`} alt="La Leyenda el Dorado"/><span></span></NavLink></li>
                <li><NavLink to={'/gallery/28/etapa6_2019'}><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/2019_etapa6.jpg`} alt="La Leyenda el Dorado"/><span></span></NavLink></li>
              </ul>

              <ul className="gallery__list">
                <li><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/2018.jpg`} alt="La leyenda 2018"/></li>
                <li><NavLink to={'/gallery/16/etapa1_2018'}><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/2018_etapa1.jpg`} alt="La Leyenda el Dorado"/><span></span></NavLink></li>
                <li><NavLink to={'/gallery/15/prologo_2018'}><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/2018_prologo.jpg`} alt="La Leyenda el Dorado"/><span></span></NavLink></li>
                <li><NavLink to={'/gallery/17/etapa2_2018'}><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/2018_etapa2.jpg`} alt="La Leyenda el Dorado"/><span></span></NavLink></li>
                <li><NavLink to={'/gallery/18/etapa3_2018'}><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/2018_etapa3.jpg`} alt="La Leyenda el Dorado"/><span></span></NavLink></li>
                <li><NavLink to={'/gallery/19/etapa4_2018'}><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/2018_etapa4.jpg`} alt="La Leyenda el Dorado"/><span></span></NavLink></li>
                <li><NavLink to={'/gallery/20/etapa5_2018'}><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/2018_etapa5.jpg`} alt="La Leyenda el Dorado"/><span></span></NavLink></li>
                <li><NavLink to={'/gallery/21/etapa6_2018'}><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/2018_etapa6.jpg`} alt="La Leyenda el Dorado"/><span></span></NavLink></li>
              </ul>

              <ul className="gallery__list">
                <li><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/2017.jpg`} alt="La leyenda 2017"/></li>
                <li><NavLink to={'/gallery/8/prologue'}><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/prologue_2017.jpg`} alt="La Leyenda el Dorado"/><span></span></NavLink></ li>
                <li><NavLink to={'/gallery/9/stage_1_2017'}><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/coffee_2017.jpg`} alt="La Leyenda el Dorado"/><span></span></NavLink></li>
                <li><NavLink to={'/gallery/10/stage_2_2017'}><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/jungle_2017.jpg`} alt="La Leyenda el Dorado"/><span></span></NavLink></li>
                <li><NavLink to={'/gallery/11/stage_3_2017'}><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/salamina_2017.jpg`} alt="La Leyenda el Dorado"/><span></span></NavLink></li>
                <li><NavLink to={'/gallery/12/stage_4_2017'}><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/legend_2017.jpg`} alt="La Leyenda el Dorado"/><span></span></NavLink></li>
                <li><NavLink to={'/gallery/13/stage_5_2017'}><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/volcanoes_2017.jpg`} alt="La Leyenda el Dorado"/><span></span></NavLink></li>
                <li><NavLink to={'/gallery/14/stage_6_2017'}><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/dorado_2017.jpg`} alt="La Leyenda el Dorado"/><span></span></NavLink></li>
              </ul>

              <ul className="gallery__list">
                <li><img className="img-responsive" src={`https://la-leyenda.com/en/img/gallery/2016.jpg`} alt="La leyenda 2016"/></li>
                <li><NavLink to={'/gallery/1/prologue'}><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/prologe_2016.jpg`} alt="La Leyenda el Dorado"/><span></span></NavLink></li>
                <li><NavLink to={'/gallery/2/stage_1_2016'}><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/stage1_2016.jpg`} alt="La Leyenda el Dorado"/><span></span></NavLink></li>
                <li><NavLink to={'/gallery/3/stage_2_2016'}><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/stage2_2016.jpg`} alt="La Leyenda el Dorado"/><span></span></NavLink></li>
                <li><NavLink to={'/gallery/4/stage_3_2016'}><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/stage3_2016.jpg`} alt="La Leyenda el Dorado"/><span></span></NavLink></li>
                <li><NavLink to={'/gallery/5/stage_4_2016'}><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/stage4_2016.jpg`} alt="La Leyenda el Dorado"/><span></span></NavLink></li>
                <li><NavLink to={'/gallery/6/stage_5_2016'}><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/stage5_2016.jpg`} alt="La Leyenda el Dorado"/><span></span></NavLink></li>
                <li><NavLink to={'/gallery/7/stage_6_2016'}><img className="img-responsive" src={`https://la-leyenda.com/img/gallery/stage6_2016.jpg`} alt="La Leyenda el Dorado"/><span></span></NavLink></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </LayoutLldd>
  )
}

export default Gallery;
