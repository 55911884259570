import React from 'react';
import {Link} from 'react-router-dom';
import '../assets/styles/components/StageMenu.sass';

const StageMenu = () => (
  <div className="row stage-menu">
    <div className="container">
      <div className="row">
        <div className="col-xs-12">
          <ul className="stage-menu__list">
            <li className="stage-menu__list--item">
              <Link id="stageResumen" to="/home-carrera">
                RESUMEN
              </Link>
            </li>
            <li className="stage-menu__list--item">
              <Link id="stagePrologo" to="/home-carrera/prologue">
                PRÓLOGO
              </Link>
            </li>
            <li className="stage-menu__list--item">
              <Link id="stageEtapa1" to="/home-carrera/stage-one">
                ETAPA 1
              </Link>
            </li>
            <li className="stage-menu__list--item">
              <Link id="stageEtapa2" to="/home-carrera/stage-two">
                ETAPA 2
              </Link>
            </li>
            <li className="stage-menu__list--item">
              <Link id="stageEtapa3" to="/home-carrera/stage-three">
                ETAPA 3
              </Link>
            </li>
            <li className="stage-menu__list--item">
              <Link id="stageEtapa4" to="/home-carrera/stage-four">
                ETAPA 4
              </Link>
            </li>
            <li className="stage-menu__list--item">
              <Link id="stageEtapa5" to="/home-carrera/stage-five">
                ETAPA 5
              </Link>
            </li>
            <li className="stage-menu__list--item">
              <Link id="stageEtapa6" to="/home-carrera/stage-six">
                ETAPA 6
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
)
  
export default StageMenu;