import React, { useEffect, useState } from "react";
import "../../assets/styles/components/TableIndividualRacer.sass";

export const TableIndividualRacer = (props) => {
  const { racers } = props;
  const [showRacers, setShowRacer] = useState([]);

  useEffect(() => {
    const racersToShow = racers.filter((racer) => racer.categoria_es !== "E-BIKE");
    setShowRacer(racersToShow);
  }, [racers]);

  return (
    <div className="row">
      <div className="hidden-xs col-sm-12">
        <section className="table-results">
          <div className="table-results__title table-results__pos-category">CLASIFICACIÓN CATEGORÍA</div>
          <div className="table-results__title table-results__pos-overall">CLASIFICACIÓN GENERAL</div>
          <div className="table-results__title table-results__category">CATEGORÍA</div>
          <div className="table-results__title table-results__number">NÚMERO CORREDOR</div>
          <div className="table-results__title table-results__racer">NOMBRE DEL CORREDOR</div>
          <div className="table-results__title table-results__time">TIEMPO TOTAL</div>
          <div className="table-results__title table-results__time">TIEMPO DIFERENCIA</div>

          {showRacers.map((racer, i) => (
            <>
              <div className="table-results__pos-category racer__red">{racer.category_rank}</div>
              <div className="table-results__pos-overall">{racer.overall_rank}</div>
              <div className="table-results__category">{racer.categoria_es}</div>
              <div className="table-results__number">
                <p>{racer.numero_corredor_1}</p>
              </div>
              <div className="table-results__racer">
                <p>
                  <img
                    src={`https://la-leyenda.com/2018/en/img/banderas/${racer.nacionalidad_corredor_1_en}.png`}
                    alt=""
                  />{" "}
                  {racer.nombre_corredor_1}
                </p>
              </div>
              <div className="table-results__time">
                <p>{racer.tiempo}</p>
              </div>
              <div className="table-results__dif racer__red">
                <p>{racer.diferencia}</p>
              </div>
            </>
          ))}
        </section>
      </div>
      <div className="visible-xs col-xs-12">
        <section className="table-results-mobile">
          {showRacers.map((racer, i) => (
            <div className="table-results-mobile__item" key={i}>
              <p>
                <b>Clasificación categoría:</b> {racer.category_rank}
              </p>
              <p>
                <b>Clasificación general:</b> {racer.overall_rank}
              </p>
              <p>
                <b>Categoría:</b> {racer.categoria_es}
              </p>
              <p>
                <b>Corredor:</b>
              </p>
              <span className="table-results-mobile__item--racer">
                {racer.numero_corredor_1}{" "}
                <img
                  src={`https://la-leyenda.com/2018/en/img/banderas/${racer.nacionalidad_corredor_1_en}.png`}
                  alt=""
                />{" "}
                {racer.nombre_corredor_1}
              </span>
              <p>
                <b>Tiempo total: </b>
                {racer.tiempo}
              </p>
              <p className="racer__red">
                <b>Tiempo diferencia: </b>
                {racer.diferencia}
              </p>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};
