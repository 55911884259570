import React, {Component} from 'react';
import Layout from '../components/Layout';
import InstagramEmbed from 'react-instagram-embed';
import '../assets/styles/components/Social.sass';

class Social extends Component{
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      content: {
        "title": "Comunicado Oficial de La Leyenda del Dorado sobre Covid-19 \ud83d\udccb\n.\nOfficial Statement from La Leyenda del Dorado about Covid-19 \ud83d\udccb\n.\nhttps://la-leyenda.com/latest-news/155/official-statement-covid-19\n.\n#SoyLeyenda20 #LeyendaSeries #Covid19 #MTB #CaliCo #Colombia",
        "author_name": "laleyendamtb",
        "author_url": "https://www.instagram.com/laleyendamtb",
        "author_id": 2212756518,
        "media_id": "2266211272470913935_2212756518",
        "provider_name": "Instagram",
        "provider_url": "https://www.instagram.com",
        "type": "rich",
        "width": 658,
        "thumbnail_url": "https://instagram.fbog15-1.fna.fbcdn.net/v/t51.2885-15/e35/s480x480/89733125_813774245768733_3325811610318398934_n.jpg?tp=1\u0026_nc_ht=instagram.fbog15-1.fna.fbcdn.net\u0026_nc_cat=109\u0026_nc_ohc=kks3QrlVHjQAX8ZTpcA\u0026edm=ALY_pVYBAAAA\u0026ccb=7-4\u0026oh=19685a414630020110857abc5bc5044b\u0026oe=60B8A3D9\u0026_nc_sid=1ffb93",
        "thumbnail_width": 480,
        "thumbnail_height": 480
      },
      media: ''
    };
  }

  // async instagramContent() {
  //   let response = await fetch('https://api.instagram.com/oembed/?url=https://www.instagram.com/p/B9zMyU5nWOP', {
  //     method: 'get',
  //     mode: 'no-cors',
  //     headers: {
  //       'Access-Control-Allow-Origin': '*',
  //     },
  //   })
  //   let data = response.json();
  //   return data;
  // }

  // async instagramMedia() {
  //   let media = await fetch('https://www.instagram.com/p/B9zMyU5nWOP/media/?size=l', {
  //     mode: 'no-cors',
  //     headers: {
  //       'Access-Control-Allow-Origin': '*',
  //     },
  //   });
  //   return media;
  // }

  async componentDidMount() {
    document.getElementById('social').classList.add('active')

    let face_content = document.getElementById('content-facebook')
    if(face_content) {
      face_content.innerHTML = '<iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Flaleyendadeldorado%2F&tabs=timeline&width=340&height=1240px&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=340116299363794" width="340" height="1240px" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>';
    }

    // await this.instagramContent()
    // .then(data => this.setState({content: data}))

    // await this.instagramMedia()
    // .then(media => this.setState({media: media}))

    // #let insta_description = document.getElementsByClassName('content-instagram__descripcion')
    // #insta_description[0].innerHTML = this.state.content.title
    // let insta_img = document.getElementsByClassName('content-instagram__img')
    // insta_img[0].src = this.state.media.url
  }

  componentWillUnmount() {
    document.getElementById('social').classList.remove('active')
  }

  render(){
    return(
      <Layout>
        <div className="row Social">
          <div className="container" id="contenido">
            <div className="row">
              <div className="col-xs-12 col-md-4 col-md-offset-1 text-center">
                <h1>FACEBOOK</h1>
                <div id="content-facebook"></div>
              </div>

              <div className="col-xs-12 col-md-4 col-md-offset-2 text-center">
                <h1>INSTAGRAM</h1>
                <InstagramEmbed
                  url='https://www.instagram.com/p/COTulMjHlUy/'
                  clientAccessToken='1094980450854392|0a9ef1b5d8e64790541d8dd9691253f4'
                  maxWidth={340}
                  hideCaption={false}
                  containerTagName='div'
                  protocol=''
                  injectScript
                  onLoading={() => {}}
                  onSuccess={() => {}}
                  onAfterRender={() => {}}
                  onFailure={() => {}}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }

}

export default Social;