import React from "react";
import setInputValue from "../hooks/useInputValue";
import "../assets/styles/components/HorizontalFormContact.sass";

const HorizontalFormContact = () => {
  const formButton = document.getElementById("horizontal-form__button");
  const formInputs = document.getElementById("horizontal-form");

  let name = setInputValue("");
  let email = setInputValue("");
  let phone = setInputValue("");
  let message = setInputValue("");

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (phone.value.startsWith(1)) {
      return;
    }

    if (name.value == "" || email.value == "") {
      formButton.innerHTML = "INGRESE INFORMACIÓN";
      const inputs = formInputs.elements;

      if (name.value == "") inputs["Nombre"].classList.add("btn-fill");
      if (email.value == "") inputs["Correo"].classList.add("btn-fill");
      formButton.classList.add("btn-fill");
      validate();
    } else {
      sendForm();
    }
  };

  const sendForm = () => {
    const formData = new FormData();
    formData.append("name", name.value);
    formData.append("email", email.value);
    formData.append("phone", phone.value);
    formData.append("mensaje", message.value);
    formData.append("languaje", "es");
    formData.append("section", "contactHome");

    fetch(`${process.env.REACT_APP_URL}/send-contact`, {
      method: "POST",
      body: formData,
    });

    const dataEmail = {
      name: name.value,
      email: email.value,
      section: "contactHome",
    };

    fetch(`${process.env.REACT_APP_SEND_EMAIL_HORIZONTAL}/horizontal-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataEmail),
    }).catch((error) => {
      console.log(error);
    });

    gracias();
  };

  const gracias = () => {
    formButton.innerHTML = "GRACIAS";
    formButton.classList.add("btn-thanks");
    resetForm();
  };

  const resetForm = () => {
    setTimeout(() => {
      const inputs = formInputs.elements;

      formButton.innerHTML = "CONTÁCTAME";
      formButton.classList.remove("btn-thanks");
      formButton.classList.remove("btn-fill");
      inputs["Nombre"].classList.remove("btn-fill");
      inputs["Correo"].classList.remove("btn-fill");
      inputs["Telefono"].classList.remove("btn-fill");
      inputs["Nombre"].value = "";
      inputs["Correo"].value = "";
      inputs["Telefono"].value = "";
    }, 3000);
  };

  const validate = () => {
    setTimeout(() => {
      const inputs = formInputs.elements;

      formButton.innerHTML = "CONTÁCTAME";
      formButton.classList.remove("btn-fill");
      inputs["Nombre"].classList.remove("btn-fill");
      inputs["Correo"].classList.remove("btn-fill");
      inputs["Telefono"].classList.remove("btn-fill");
    }, 3000);
  };

  return (
    <>
      <div className="row no-gutters">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-xs-12">
              <form id="horizontal-form" className="horizontal-form" onSubmit={(e) => handleOnSubmit(e)}>
                <input type="text" name="Nombre" placeholder="Nombre" {...name} />
                <input type="email" name="Correo" placeholder="Correo" {...email} />
                <input type="text" name="Telefono" placeholder="Teléfono (Opcional)" {...phone} />
                <input type="hidden" value="Español" />
                <button id="horizontal-form__button" type="submit">
                  CONTÁCTAME
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HorizontalFormContact;
