import React, {Component} from 'react';
import Layout from '../components/Layout';
import '../assets/styles/components/News.sass';

class News extends Component{
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      news: [],
      items: [],
      pos: 1,
      showResults: true,
    }

    this.handleShow = this.handleShow.bind(this);
  }

  componentDidMount() {
    fetch('https://la-leyenda.com/2018/api/news')
    .then(res => res.json())
    .then(
      (res) => {
        this.setState({
          isLoaded: true,
          news: res,
          items: res[0]
        });
      }
    )

    document.getElementById('news').classList.add('active')
  }

  componentWillUnmount() {
    document.getElementById('news').classList.remove('active')
  }

  handleShow() {
    let elementsQuantity = this.state.news.length * 6;
    let inView = this.state.items.length + 6;
    
    if (inView < elementsQuantity) {
      let temp = Object.values(this.state.news[this.state.pos]);

      this.setState({
        items: [...this.state.items, ...temp],
        pos: this.state.pos + 1
      })
    }
    else {
      this.setState({showResults: false})
    }
  }

  render(){
    const { error, isLoaded, items } = this.state;

    return(
      <Layout>
        <div className="row">
          <div className="container contenido_min">
            <div className="row">
              <div className="col-xs-12">
                <div className="news-container">
                  {items.map((item, id) => (
                    <a key={id} className="news-container__item" href={`https://la-leyenda.com/latest-news/${item.id}/${item.slug}`}>
                      <div className="n-item" key={item.id}>
                        <img className="n-item__img" src={`https://la-leyenda.com/2018/en/img/news/${item.picture}`} alt=""/>
                        <div className="n-item__text">
                          <p className="n-item__text--tag"><span>#</span>{item.etiqueta_es}</p>
                          <p>{item.title_es}</p>
                          <p className="n-item__text--date">{item.date}</p>
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
              </div>

              <div className="col-xs-12 text-center">
                { this.state.showResults ? <button className="News-load-more" onClick={this.handleShow}>Más noticias</button> : null }
              </div>

            </div>
          </div>
        </div>
      </Layout>
    );
  }

}

export default News;