import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import NewsGallery from '../components/NewsGallery';
import LayoutLldd from '../components/LayoutLldd';

import '../assets/styles/containers/LatestNewDetails.sass';

class LatestNewDetail extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      noticia: [],
      news: [],
      diferent: [],
      pictures: [],
    }
  }
  
  componentDidMount() {
    const {id, slug} = this.props.match.params;
    this.getData(id, slug);

    let face_content = document.getElementsByClassName('new-detail__facebook');
    face_content[0].innerHTML = '<iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Flaleyendadeldorado%2F&tabs=timeline&width=340&height=740px&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=340116299363794" width="340" height="740px" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>';
  }

  componentWillReceiveProps(nextProps) {
    const {id, slug} = this.props.match.params;
    if(id !== nextProps.match.params.id || slug !== nextProps.match.params.slug) {
        this.getData(nextProps.match.params.id, nextProps.match.params.slug);
    }
 }

  getData(id, slug) {
    fetch(`https://la-leyenda.com/2018/api/new/${id}/${slug}`)
    .then(res => res.json())
    .then(
      (res) => {
        this.setState({
          isLoaded: true,
          noticia: res.new,
          news: res.news,
          diferent: res.diferent,
          pictures: res.pictures,
        });
      },
      (error) => {
        this.setState({isLoaded: true, error});
      }
    )
    return;
  }

  render() {
    const {noticia, news, diferent, pictures } = this.state;

    return(
      <LayoutLldd>
        <div className="row new-detail">
          <div className="container">
            <div className="row no-gutters">

            <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                <h1 className="new-detail__title">{noticia.title_es}</h1>
                <p className="new-detail__hashtag"><span>#</span>{noticia.etiqueta_es}</p>
                <p className="new-detail__date">{noticia.date}</p>
                <div className="new-detail__content">
                  { ReactHtmlParser(noticia.content_top_es) }
                </div>
                {pictures &&
                  <NewsGallery pictures={pictures} />
                }
                <div className="new-detail__content">
                  { ReactHtmlParser(noticia.content_es) }
                </div>

                <div className="col-xs-12 text-right">
                  <p><button className="new-detail__button" onClick={() => this.props.history.goBack()}>Regresar</button></p>
                </div>
              </div>

              <div className="hidden-xs col-sm-4 col-md-4 col-lg-4">
                <div className="new-detail__facebook"></div>
              </div>

              <div className="row">
                <div className="col-xs-12">
                  <div className="row new-detail__recomendations">
                  <div className="col-xs-12">
                    <h3>Más noticias parecidas</h3>
                    <div className="row">

                      {news.length > 0 &&
                        <>
                          <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 new-detail__image--onlyxsmb">
                            <NavLink to={`/latest-news/${news[0].id}/${news[0].slug}`}>
                              <div className="new-detail__image">
                                <img className="img-responsive" src={`${process.env.REACT_APP_IMG_URL}/img/news/${news[0].picture}`} alt="La Leyenda" />
                                <div>
                                  <p><span>#</span>{news[0].etiqueta_es}</p>
                                  <p>{news[0].title_es}</p>
                                  <p>{news[0].date}</p>
                                </div>
                              </div>
                            </NavLink>
                          </div>
                          {news[1] &&
                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 new-detail__image--mb">
                              <NavLink to={`/latest-news/${news[1].id}/${news[1].slug}`}>
                                <div className="new-detail__image">
                                  <img className="img-responsive" src={`${process.env.REACT_APP_IMG_URL}/img/news/${news[1].picture}`} alt="La Leyenda" />
                                  <div>
                                    <p><span>#</span>{news[1].etiqueta_es}</p>
                                    <p>{news[1].title_es}</p>
                                    <p>{news[1].date}</p>
                                  </div>
                                </div>
                              </NavLink>
                            </div>
                          }
                        </>
                      }
                      
                      <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                        <NavLink to={`/latest-news/${diferent.id}/${diferent.slug}`}>
                          <div className="new-detail__image">
                            <img className="img-responsive" src={`${process.env.REACT_APP_IMG_URL}/img/news/${diferent.picture}`} alt="La Leyenda" />
                            <div>
                              <p><span>#</span>{diferent.etiqueta_es}</p>
                              <p>{diferent.title_es}</p>
                              <p>{diferent.date}</p>
                            </div>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </LayoutLldd>
    )
  }

}

export default LatestNewDetail;
