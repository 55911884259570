import React, { Component } from "react";
import Table from "../components/Table";
import TableIndividual from "../components/TableIndividual";
import {
  TableIndividualRacer,
  TableIndividualRacerDNF,
  TableIndividualRacerEbike,
} from "../components/TableIndividualRacer";
import LayoutLldd from "../components/LayoutLldd";
import BannerInternal from "../components/BannerInternal";
import "../assets/styles/components/Results.sass";

class MainResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      segments: [],
      categories: [],
      nationalitiesEs: [],
      nationalitiesEn: [],
      racers: [],
      racers_finishers: [],
      racers_nofinishers: [],
      racers_view: [],
      form_segment: "",
      form_category: "",
      form_search: "",
      anio: 2023,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeYear = this.changeYear.bind(this);
  }

  componentDidMount() {
    this.resultsByYear(this.state.anio);
  }

  async resultsByYear(year) {
    await fetch(`https://la-leyenda.com/2018/api/results-year/${year}`)
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          segments: res.segments.reverse(),
          form_segment: res.segments[0].segmento_es,
          racers: res.segments[0].results.racers,
          racers_finishers: res.segments[0].results.finishers,
          racers_nofinishers: res.segments[0].results.nofinishers,
          categories: res.categories,
          nationalitiesEs: res.nationalitiesEs,
        });
      });
    return;
  }

  setResultsState(data) {
    const { segments } = this.state;
    const segment = segments.find((item) => item.segmento_es === data);
    return Promise.resolve(
      this.setState({
        racers: segment.results.racers,
        racers_finishers: segment.results.finishers,
        racers_nofinishers: segment.results.nofinishers,
        form_segment: data,
      })
    );
  }

  setCategoryState(category) {
    const { racers, racers_finishers, racers_nofinishers } = this.state;
    return Promise.resolve(
      this.setState({
        racers: racers.filter((racer) => racer.categoria_es === category),
        racers_finishers: racers_finishers.filter((racer) => racer.categoria_es === category),
        racers_nofinishers: racers_nofinishers.filter((racer) => racer.categoria_es === category),
        form_category: category,
      })
    );
  }

  setSearchState(keyword) {
    const { racers, racers_finishers, racers_nofinishers } = this.state;
    return Promise.resolve(
      this.setState({
        racers: racers.filter(
          (racer) =>
            racer.nombre_corredor_1.includes(keyword.toUpperCase()) ||
            racer.nombre_corredor_2.includes(keyword.toUpperCase())
        ),
        racers_finishers: racers_finishers.filter((racer) => racer.nombre.includes(keyword.toUpperCase())),
        racers_nofinishers: racers_nofinishers.filter((racer) => racer.nombre.includes(keyword.toUpperCase())),
        form_search: keyword,
      })
    );
  }

  setNacionalityState(nacionality) {
    const { racers, racers_finishers, racers_nofinishers } = this.state;
    return Promise.resolve(
      this.setState({
        racers: racers.filter(
          (racer) =>
            racer.nacionalidad_corredor_1_es.includes(nacionality.toUpperCase()) ||
            racer.nacionalidad_corredor_2_es.includes(nacionality.toUpperCase())
        ),
        racers_finishers: racers_finishers.filter((racer) => racer.nacionalidad.includes(nacionality.toUpperCase())),
        racers_nofinishers: racers_nofinishers.filter((racer) =>
          racer.nacionalidad.includes(nacionality.toUpperCase())
        ),
      })
    );
  }

  changeYear(event) {
    this.setState({
      anio: event.target.value,
    });
    this.resultsByYear(event.target.value);
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setCategoryState("");
    this.setSearchState("");
    let target = event.target;
    try {
      await this.setResultsState(target.resultados.value);
      if (target.categorias.value) {
        await this.setCategoryState(target.categorias.value);
      }
      if (target.search_racer.value) {
        await this.setSearchState(target.search_racer.value);
      }
      if (target.nacionality.value) {
        await this.setNacionalityState(target.nacionality.value);
      }
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const {
      error,
      segments,
      categories,
      nationalitiesEs,
      racers,
      racers_finishers,
      racers_nofinishers,
      anio,
      form_category,
    } = this.state;
    const isIndividualRace = String(anio) === "2023";

    if (error) {
      return <div className="contenido_min">Error: {error.message}</div>;
    } else {
      return (
        <LayoutLldd>
          <BannerInternal image="https://la-leyenda.com/en/img/banners_contenido/results.jpg" />
          <div className="row main-results">
            <div className="container contenido_min">
              <div className="row">
                <div className="col-xs-12 text-center">
                  <h1 className="main-results__title">RESULTADOS</h1>
                </div>
                <form onSubmit={this.handleSubmit} className="form-horizontal results-form">
                  <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 text-left">
                    <div className="form-group">
                      <label htmlFor="anio" className="col-sm-4 ptop">
                        Año
                      </label>
                      <div className="col-sm-8">
                        <select name="anio" id="anio" className="form-control" onChange={this.changeYear}>
                          <option value="2023">2023</option>
                          <option value="2022">2022</option>
                          <option value="2019">2019</option>
                          <option value="2018">2018</option>
                          <option value="2017">2017</option>
                          <option value="2016">2016</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 text-left">
                    <div className="form-group">
                      <label htmlFor="resultados" className="col-sm-4 ptop">
                        Resultados
                      </label>
                      <div className="col-sm-8">
                        <select name="resultados" id="resultados" className="form-control">
                          {segments.map((segment, i) => (
                            <option key={`seg-${i}`} value={segment.segmento_es}>
                              {segment.segmento_es}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 text-left">
                    <div className="form-group">
                      <label htmlFor="categorias" className="col-sm-4 ptop">
                        Categoría
                      </label>
                      <div className="col-sm-8">
                        <select name="categorias" id="categorias" className="form-control">
                          <option value="">TODAS</option>
                          {categories.map((categorie, i) => (
                            <option key={`cat-${i}`} value={categorie.categoria_es}>
                              {categorie.categoria_es}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 text-left form-margin">
                    <div className="form-group">
                      <label htmlFor="nacionality" className="col-sm-4 ptop">
                        Nacionalidad
                      </label>
                      <div className="col-sm-8">
                        <select name="nacionality" id="nacionality" className="form-control">
                          <option value="">TODAS</option>
                          {nationalitiesEs.map((nal, i) => {
                            if (nal !== "") {
                              return (
                                <option key={`nal-${i}`} value={nal}>
                                  {nal}
                                </option>
                              );
                            }
                          })}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 text-left form-margin">
                    <div className="form-group">
                      <label htmlFor="search_racer" className="col-sm-4 control-label-left">
                        Buscar corredores
                      </label>
                      <div className="col-sm-8">
                        <input className="form-control" type="text" name="search_racer" />
                      </div>
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 text-right form-margin">
                    <button className="results-form__btn" type="submit">
                      VER RESULTADOS
                    </button>
                  </div>
                </form>
              </div>

              {!isIndividualRace ? (
                <>
                  <div className="row">
                    <div className="col-xs-12">
                      <h3>
                        {this.state.anio} - {this.state.form_segment}{" "}
                        {this.state.form_category ? ` - ${this.state.form_category}` : ""}{" "}
                      </h3>
                      <p>{this.state.form_search ? `Búsqueda: ${this.state.form_search}` : ""} </p>
                    </div>
                  </div>
                  <Table racers={racers} />

                  {racers_finishers.length > 0 && (
                    <>
                      <div className="row">
                        <div className="col-xs-12">
                          <h3>
                            {this.state.anio} - {this.state.form_segment}{" "}
                            {this.state.form_category ? ` - ${this.state.form_category}` : ""} - finalizador individual
                          </h3>
                        </div>
                      </div>

                      <TableIndividual racers={racers_finishers} />
                    </>
                  )}

                  {racers_nofinishers.length > 0 && (
                    <>
                      <div className="row">
                        <div className="col-xs-12">
                          <h3>
                            {this.state.anio} - {this.state.form_segment}{" "}
                            {this.state.form_category ? ` - ${this.state.form_category}` : ""} - no finalizador
                          </h3>
                        </div>
                      </div>

                      <TableIndividual racers={racers_nofinishers} />
                    </>
                  )}
                </>
              ) : (
                <>
                  {form_category !== "E-BIKE" && (
                    <>
                      <div className="row">
                        <div className="col-xs-12">
                          <h3>
                            {this.state.anio} - {this.state.form_segment}{" "}
                            {this.state.form_category ? ` - ${this.state.form_category}` : ""}{" "}
                          </h3>
                          <p>{this.state.form_search ? `Búsqueda: ${this.state.form_search}` : ""} </p>
                        </div>
                      </div>

                      <TableIndividualRacer racers={racers} />

                      {racers_nofinishers.length > 0 && (
                        <>
                          <div className="row">
                            <div className="col-xs-12">
                              <h3>
                                {this.state.anio} - {this.state.form_segment}{" "}
                                {this.state.form_category ? ` - ${this.state.form_category}` : ""} - no finalizador
                              </h3>
                            </div>
                          </div>

                          <TableIndividualRacerDNF racers={racers_nofinishers} />
                        </>
                      )}
                    </>
                  )}

                  {form_category === "E-BIKE" || form_category === "" ? (
                    <>
                      <div className="row">
                        <div className="col-xs-12">
                          <h3>
                            {this.state.anio} - {this.state.form_segment} - E-BIKE
                          </h3>
                        </div>
                      </div>
                      <TableIndividualRacerEbike racers={racers} racersDnf={racers_nofinishers} />
                    </>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </LayoutLldd>
      );
    }
  }
}

export default MainResults;
