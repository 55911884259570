import React, { useEffect, useState } from 'react'
import '../assets/styles/components/Leaders.sass'

const Leaders = () => {
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');

  useEffect(() => {
    const Leaders = window.sessionStorage.getItem('leaders');
    if (Leaders) {
      setContent(JSON.parse(window.sessionStorage.getItem('leaders')).content_es);
      setTitle(JSON.parse(window.sessionStorage.getItem('leaders')).title_es);
    } else {
      fetch('https://la-leyenda.com/2018/api/leaders')
      .then(res => res.json())
      .then(
        (res) => {
          setLocalStorage(res);
        },
        (error) => {
          console.error(error);
        });
    }
  }, []);

  const setLocalStorage = (data) => {
    try {
      window.sessionStorage.setItem('leaders', JSON.stringify(data));
      setContent(data.content_es);
      setTitle(data.title_es);
    } catch (error) {
      console.error(error);
    }
    return;
  }

  return (
    <div className="row">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 text-center">
            <h1>{title}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12" dangerouslySetInnerHTML={{
            __html: content,
          }}/>
        </div>
      </div>
    </div>
  );

};

export default Leaders;
