import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import LayoutLldd from '../components/LayoutLldd';
import BannerInternal from '../components/BannerInternal';

import '../assets/styles/containers/Faqs.sass'

class Faqs extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      faq: {},
      questions: []
    }
  }
  
  componentDidMount() {
    const {slug} = this.props.match.params;
    this.getData(slug);
  }

  componentWillReceiveProps(nextProps) {
    const {slug} = this.props.match.params;
    if(slug !== nextProps.match.params.slug) {
      this.getData(nextProps.match.params.slug);
    }
 }

  getData(slug) {
    fetch(`${process.env.REACT_APP_URL}/faqs/${slug}`)
    .then(res => res.json())
    .then(
      (res) => {
        this.setState({faq: res.faq, questions: res.questions});
      },
      (error) => {
        this.setState({error: error});
      }
    )
    return;
  }

  render() {
    const {faq, questions} = this.state;

    return(
      <LayoutLldd>
        <BannerInternal image={faq.banner} />
        <div className="row">
          <div className="container faqs">
            <div className="row no-gutters">
              <div className="col-xs-12 text-center">
                <h1 className="faqs__main-title">{faq.name_es}</h1>
              </div>
              <div className="col-xs-12">
                <div className="panel-group accordion_certeza" id="accordion" role="tablist" aria-multiselectable="false">
                  {questions.map((item, key) => {
                    return(
                      <div className="panel panel-default" key={key}>
                        <div className="panel-heading" role="tab" id="heading">
                          <h4 className="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#accordion" href={`#${item.id}`} aria-expanded="false" aria-controls={`${item.id}`}>
                                {item.title_es}
                            </a>
                          </h4>
                        </div>
                        <div id={`${item.id}`} className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading">
                          <div className="panel-body">
                            { ReactHtmlParser(item.content_es) }
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutLldd>
    )
  }
}
  
export default Faqs;
