import React, {Component} from 'react';
import Layout from '../components/Layout';
import GalleryMenu from '../components/GalleryMenu';
import Gallery from '../components/Gallery';

class GalleryPrologue extends Component{
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            data: [],
        }
    }

    componentDidMount() {
        fetch('https://la-leyenda.com/2018/api/gallery/prologo')
        .then(res => res.json())
        .then(
          (res) => {
            this.setState({
              isLoaded: true,
              data: res
            });
          },
          
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
      
        document.getElementById('gallery').classList.add('active')
        document.getElementById('galleryPrologo').classList.add('active')

    }
  
    componentWillUnmount() {
        document.getElementById('gallery').classList.remove('active')
        document.getElementById('galleryPrologo').classList.remove('active')
    }

    render(){
        const { data } = this.state;
        
        return(
            <Layout>
                <GalleryMenu/>
                <div className="row">
                    <div className="container" id="contenido_video">
                        <div className="row">
                            <div className="col-xs-12">
                                <Gallery images={data}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default GalleryPrologue;