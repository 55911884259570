import React from "react";
import ReactHtmlParser from "react-html-parser";
import LayoutLldd from "../components/LayoutLldd";
import RoutesMenu from "../components/RoutesMenu";
import RoutesMenu5Stages from "../components/RoutesMenu5Stages";
import BannerInternal from "../components/BannerInternal";

import "../assets/styles/components/Routes.sass";

class Routes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      data: [],
    };
  }

  componentDidMount() {
    const { year, stage } = this.props.match.params;
    this.getData(year, stage);
  }

  componentWillReceiveProps(nextProps) {
    const { year, stage } = this.props.match.params;
    if (year !== nextProps.match.params.year || stage !== nextProps.match.params.stage) {
      this.getData(nextProps.match.params.year, nextProps.match.params.stage);
    }
  }

  getData(year, stage) {
    fetch(`https://la-leyenda.com/2018/api/routes/${year}/${stage}`)
      .then((res) => res.json())
      .then(
        (res) => {
          this.setState({ isLoaded: true, data: res });
        },
        (error) => {
          this.setState({ isLoaded: true, error });
        }
      );
  }

  render() {
    const { banner, content_es } = this.state.data;
    const { year } = this.props.match.params;
    const menu5Stages = ["2023", "2024"];
    return (
      <LayoutLldd>
        <BannerInternal image={`https://la-leyenda.com/2018/en/img/banner/${banner}`} />
        <div className="row no-gutters"></div>
        {menu5Stages.includes(year) ? <RoutesMenu5Stages year={year} /> : <RoutesMenu year={year} />}
        <div className="row">
          <div className="container">
            <div className="row no-gutters">{ReactHtmlParser(content_es)}</div>
          </div>
        </div>
      </LayoutLldd>
    );
  }
}

export default Routes;
