import React, {Component} from 'react';
import TinySliderWrapper from "tiny-slider-react-wrapper";
import '../assets/styles/components/Sponsors.sass';

class Sponsors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    }
  }
  
  componentDidMount() {
    fetch('https://la-leyenda.com/2018/api/sponsors')
    .then(res => res.json())
    .then(
      (res) => {
        this.setState({
          isLoaded: true,
          items: res
        });
      },
      
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
  }
    
  render() {
    const { error, isLoaded, items } = this.state;

    const settings = {
      lazyload: true,
      items: 1,
      nav: false,
      controls: false,
      mouseDrag: true,
      speed: 700,
      autoplay: true,
      autoplayHoverPause: true,
      autoplayTimeout: 2000,
      autoplayButtonOutput: false,
      responsive: {
        600: {
          items: 3
        },
        900: {
          items: 4
        },
        1200: {
          items: 5
        }
      }
    };

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Cargando...</div>;
    } else {
      return (
        <div className="row sponsors-container">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
              <TinySliderWrapper settings={settings}>
                {items.map(item => (
                  <div key={item.id}>
                    <a href={item.web} target="blank">
                      <img 
                        src={`https://la-leyenda.com/2018/en/img/marcas_in/${item.logo_mono}`}
                        alt=""
                        onMouseOut={e => (e.currentTarget.src = `https://la-leyenda.com/2018/en/img/marcas_in/${item.logo_mono}`)}
                        onMouseOver={e => (e.currentTarget.src = `https://la-leyenda.com/2018/en/img/marcas_in/${item.logo}`)}
                      />
                    </a>
                  </div>
                ))}
              </TinySliderWrapper>
            </div>
        </div>
      );
    }
  }
}
        
export default Sponsors;