import React from 'react';
import {Link} from 'react-router-dom';
import '../assets/styles/components/StageMenu.sass';

const GalleryMenu = () => (
  <div className="row stage-menu">
    <div className="container">
      <div className="row">
        <div className="col-xs-12">
          <ul className="stage-menu__list">
            <li className="stage-menu__list--item">
              <Link id="galleryRegistrations" to="/home-carrera/gallery-registrations">
                REGISTROS
              </Link>
            </li>
            <li className="stage-menu__list--item">
              <Link id="galleryPrologo" to="/home-carrera/gallery-prologue">
                PRÓLOGO
              </Link>
            </li>
            <li className="stage-menu__list--item">
              <Link id="galleryEtapa1" to="/home-carrera/gallery-stage-one">
                ETAPA 1
              </Link>
            </li>
            <li className="stage-menu__list--item">
              <Link id="galleryEtapa2" to="/home-carrera/gallery-stage-two">
                ETAPA 2
              </Link>
            </li>
            <li className="stage-menu__list--item">
              <Link id="galleryEtapa3" to="/home-carrera/gallery-stage-three">
                ETAPA 3
              </Link>
            </li>
            <li className="stage-menu__list--item">
              <Link id="galleryEtapa4" to="/home-carrera/gallery-stage-four">
                ETAPA 4
              </Link>
            </li>
            <li className="stage-menu__list--item">
              <Link id="galleryEtapa5" to="/home-carrera/gallery-stage-five">
                ETAPA 5
              </Link>
            </li>
            <li className="stage-menu__list--item">
              <Link id="galleryEtapa6" to="/home-carrera/gallery-stage-six">
                ETAPA 6
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
)
  
export default GalleryMenu;