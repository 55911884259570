import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Home from '../containers/Home';
import HomePackages from '../containers/HomePackages';
import HomePrices from '../containers/HomePrices';
import HomePayment from '../containers/HomePayment';
import HomeCategories from '../containers/HomeCategories';
import HomeTestimonials from '../containers/HomeTestimonials';
import HomeNoExcuses from '../containers/HomeNoExcuses';
import HomeNews from '../containers/HomeNews';
import WhatsIncluded from '../containers/WhatsIncluded';
import Routes from '../containers/Routes';
import Faqs from '../containers/Faqs';
import LatestNews from '../containers/LatestNews';
import LatestNewsDetail from '../containers/LatestNewsDetail';
import SimpleSection from '../containers/SimpleSection';
import TipsTricks from '../containers/TipsTricks';
import FindPartner from '../containers/FindPartner';
import Jaguares from '../containers/Jaguares';
import SponsorsAndPartner from '../containers/SponsorsAndPartner';
import Gallery from '../containers/Gallery';
import GalleryDetail from '../containers/GalleryDetail';
import MainResults from '../containers/MainResults';
import ContactUs from '../containers/ContactUs';
import Videos from '../containers/Videos';


import HomeCarrera from '../containers/HomeCarrera';
import Prologue from '../containers/Prologue';
import StageOne from '../containers/StageOne';
import StageTwo from '../containers/StageTwo';
import StageThree from '../containers/StageThree';
import StageFour from '../containers/StageFour';
import StageFive from '../containers/StageFive';
import StageSix from '../containers/StageSix';
import SpectatorExpo from '../containers/SpectatorExpo';
import SpectatorPrologue from '../containers/SpectatorPrologue';
import SpectatorStageOne from '../containers/SpectatorStageOne';
import SpectatorStageTwo from '../containers/SpectatorStageTwo';
import SpectatorStageThree from '../containers/SpectatorStageThree';
import SpectatorStageFour from '../containers/SpectatorStageFour';
import SpectatorStageFive from '../containers/SpectatorStageFive';
import SpectatorStageSix from '../containers/SpectatorStageSix';
import GalleryRegistrations from '../containers/GalleryRegistrations';
import GalleryPrologue from '../containers/GalleryPrologue';
import GalleryStageOne from '../containers/GalleryStageOne';
import GalleryStageTwo from '../containers/GalleryStageTwo';
import GalleryStageThree from '../containers/GalleryStageThree';
import GalleryStageFour from '../containers/GalleryStageFour';
import GalleryStageFive from '../containers/GalleryStageFive';
import GalleryStageSix from '../containers/GalleryStageSix';
import News from '../containers/News';
import Results from '../containers/Results';
import VideosPrologue from '../containers/VideosPrologue';
import VideosStageOne from '../containers/VideosStageOne';
import VideosStageTwo from '../containers/VideosStageTwo';
import VideosStageThree from '../containers/VideosStageThree';
import VideosStageFour from '../containers/VideosStageFour';
import VideosStageFive from '../containers/VideosStageFive';
import VideosStageSix from '../containers/VideosStageSix';
import Social from '../containers/Social';

import NotFound from '../containers/NotFound';

const App = () => (
    <BrowserRouter basename="/">
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/home/packages" component={HomePackages} />
            <Route exact path="/home/prices" component={HomePrices} />
            <Route exact path="/home/payment" component={HomePayment} />
            <Route exact path="/home/categories" component={HomeCategories} />
            <Route exact path="/home/news" component={HomeNews} />
            <Route exact path="/home/no-excuses" component={HomeNoExcuses} />
            <Route exact path="/home/testimonials" component={HomeTestimonials} />
            <Route exact path="/about-us/packages-upgrades" component={WhatsIncluded} />
            <Route exact path="/about-us/:slug" component={SimpleSection} />
            <Route exact path="/route/:slug" component={SimpleSection} />
            <Route exact path="/route/:year/:stage" component={Routes} />
            <Route exact path="/racers/tips-and-tricks" component={TipsTricks} />
            <Route exact path="/racers/find-a-partner" component={FindPartner} />
            <Route exact path="/racers/jaguares" component={Jaguares} />
            <Route exact path="/racers/:slug" component={SimpleSection} />
            <Route exact path="/latest-news" component={LatestNews} />
            <Route exact path="/latest-news/:id/:slug" component={LatestNewsDetail} />
            <Route exact path="/faqs/:slug" component={Faqs} />
            <Route exact path="/sponsors-and-partner" component={SponsorsAndPartner} />
            <Route exact path="/sponsors-and-partner/:slug" component={SimpleSection} />
            <Route exact path="/news-and-media/:slug" component={SimpleSection} />
            <Route exact path="/videos/:year" component={Videos} />
            <Route exact path="/gallery" component={Gallery} />
            <Route exact path="/gallery/:id/:slug" component={GalleryDetail} />
            <Route exact path="/results" component={MainResults} />
            <Route exact path="/contact-us" component={ContactUs} />
            <Route exact path="/new/:slug" component={SimpleSection} />

            <Route exact path="/home-carrera" component={HomeCarrera} />
            <Route exact path="/home-carrera/prologue" component={Prologue} />
            <Route exact path="/home-carrera/stage-one" component={StageOne} />
            <Route exact path="/home-carrera/stage-two" component={StageTwo} />
            <Route exact path="/home-carrera/stage-three" component={StageThree} />
            <Route exact path="/home-carrera/stage-four" component={StageFour} />
            <Route exact path="/home-carrera/stage-five" component={StageFive} />
            <Route exact path="/home-carrera/stage-six" component={StageSix} />
            <Route exact path="/home-carrera/spectator-expo" component={SpectatorExpo} />
            <Route exact path="/home-carrera/spectator-prologue" component={SpectatorPrologue} />
            <Route exact path="/home-carrera/spectator-stage-one" component={SpectatorStageOne} />
            <Route exact path="/home-carrera/spectator-stage-two" component={SpectatorStageTwo} />
            <Route exact path="/home-carrera/spectator-stage-three" component={SpectatorStageThree} />
            <Route exact path="/home-carrera/spectator-stage-four" component={SpectatorStageFour} />
            <Route exact path="/home-carrera/spectator-stage-five" component={SpectatorStageFive} />
            <Route exact path="/home-carrera/spectator-stage-six" component={SpectatorStageSix} />
            <Route exact path="/home-carrera/gallery-registrations" component={GalleryRegistrations} />
            <Route exact path="/home-carrera/gallery-prologue" component={GalleryPrologue} />
            <Route exact path="/home-carrera/gallery-stage-one" component={GalleryStageOne} />
            <Route exact path="/home-carrera/gallery-stage-two" component={GalleryStageTwo} />
            <Route exact path="/home-carrera/gallery-stage-three" component={GalleryStageThree} />
            <Route exact path="/home-carrera/gallery-stage-four" component={GalleryStageFour} />
            <Route exact path="/home-carrera/gallery-stage-five" component={GalleryStageFive} />
            <Route exact path="/home-carrera/gallery-stage-six" component={GalleryStageSix} />
            <Route exact path="/home-carrera/news" component={News} />
            <Route exact path="/home-carrera/results" component={Results} />
            <Route exact path="/home-carrera/videos-prologue" component={VideosPrologue} />
            <Route exact path="/home-carrera/videos-stage-one" component={VideosStageOne} />
            <Route exact path="/home-carrera/videos-stage-two" component={VideosStageTwo} />
            <Route exact path="/home-carrera/videos-stage-three" component={VideosStageThree} />
            <Route exact path="/home-carrera/videos-stage-four" component={VideosStageFour} />
            <Route exact path="/home-carrera/videos-stage-five" component={VideosStageFive} />
            <Route exact path="/home-carrera/videos-stage-six" component={VideosStageSix} />
            <Route exact path="/home-carrera/social" component={Social} />

            <Route component={NotFound} />
        </Switch>
    </BrowserRouter>
);
    
export default App;