import React, { useEffect, useState} from 'react';
import ReactHtmlParser from 'react-html-parser';

import LayoutLldd from '../components/LayoutLldd';
import BannerInternal from '../components/BannerInternal';

import '../assets/styles/containers/WhatsIncluded.sass';

const WhatsIncluded = () => {
  const [intro, setIntro] = useState({});
  const [altiplano, setAltiplano] = useState({});
  const [nevado, setNevado] = useState({});
  const [ascensos, setAscensos] = useState({});
  const [supporter, setSupporter] = useState({});

  useEffect(() => {
    fetch(`https://la-leyenda.com/2018/api/general/whats-included`)
    .then(res => res.json())
    .then(
      (res) => {
        setIntro(res.intro);
        setAltiplano(res.altiplano);
        setNevado(res.nevado);
        setSupporter(res.supporter);
        setAscensos(res.ascensos);
      }
      )
    }, []);

  return(
    <LayoutLldd
      title={intro.seo_title_es}
      description={intro.seo_content_es}
    >
      <BannerInternal image="https://la-leyenda.com/img/banners_contenido/whats_included.jpg" />
      <div className="row whats-included">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-xs-12">
              { ReactHtmlParser(intro.content_es) }
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-xs-12">
                  <ul className="whats-included-nav nav-tabs" id="tabs_home" role="tablist">
                    <li role="presentation" className="active"><a href="#altiplano" aria-controls="altiplano" role="tab" data-toggle="tab">Paquete Altiplano</a></li>
                    <li role="presentation"><a href="#vip" aria-controls="vip" role="tab" data-toggle="tab">Paquete Nevado</a></li>
                    <li role="presentation"><a href="#ascensos" aria-controls="ascensos" role="tab" data-toggle="tab">Ascensos y servicios</a></li>
                    <li role="presentation"><a href="#supporters" aria-controls="supporters" role="tab" data-toggle="tab">Paquete de Acompañante</a></li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-xs-12 no-padding">
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane fade in active" id="altiplano">
                  { ReactHtmlParser(altiplano.content_es) }
                </div>
                <div role="tabpanel" className="tab-pane fade" id="vip">
                  { ReactHtmlParser(nevado.content_es) }
                </div>
                <div role="tabpanel" className="tab-pane fade" id="ascensos">
                  { ReactHtmlParser(ascensos.content_es) }
                </div>
                <div role="tabpanel" className="tab-pane fade" id="supporters">
                  { ReactHtmlParser(supporter.content_es) }
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </LayoutLldd>
  )
}

export default WhatsIncluded;
