import React from 'react';
import {Link} from 'react-router-dom';
import '../assets/styles/components/Menu.sass';

const Menu = () => (
  <div className="row internal-menu">
    <div className="container">
      <div className="row">
        <div className="col-xs-12">
          <ul className="internal-menu__list">
            <li className="internal-menu__list--item internal-menu-list">
              <Link id="stages" to="/home-carrera">
                <span className="internal-menu-icon internal-menu-list__stage-info"></span>
                <p>ETAPAS</p>
              </Link>
            </li>
            <li className="internal-menu__list--item internal-menu-list">
              <Link id="spectators" to="/home-carrera/spectator-expo">
                <span className="internal-menu-icon internal-menu-list__spectator-info"></span>
                <p>ESPECTADORES</p>
              </Link>
            </li>
            <li className="internal-menu__list--item internal-menu-list">
              <Link id="news" to="/home-carrera/news">
                <span className="internal-menu-icon internal-menu-list__news"></span>
                <p>NOTICIAS</p>
              </Link>
            </li>
            <li className="internal-menu__list--item internal-menu-list">
              <Link id="results" to="/home-carrera/results">
                <span className="internal-menu-icon internal-menu-list__results"></span>
                <p>RESULTADOS</p>
              </Link>
            </li>
            <li className="internal-menu__list--item internal-menu-list">
              <Link id="videos" to="/home-carrera/videos-prologue">
                <span className="internal-menu-icon internal-menu-list__videos"></span>
                <p>VIDEOS</p>
              </Link>
            </li>
            <li className="internal-menu__list--item internal-menu-list">
              <Link id="gallery" to="/home-carrera/gallery-registrations">
                <span className="internal-menu-icon internal-menu-list__gallery"></span>
                <p>GALERÍA</p>
              </Link>
            </li>
            <li className="internal-menu__list--item internal-menu-list">
              <Link id="social" to="/home-carrera/social">
                <span className="internal-menu-icon internal-menu-list__social"></span>
                <p>REDES SOCIALES</p>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
)
  
export default Menu;