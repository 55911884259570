import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import LayoutLldd from '../components/LayoutLldd';
import BannerDinamic from '../components/BannerDinamic';
import VideosMainMenu from '../components/VideosMainMenu';
import '../assets/styles/containers/Videos.sass';

class Videos extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      data: [],
      year: ''
    }
  }

  componentDidMount() {
    const {year} = this.props.match.params;
    this.getData(year);
  }

  componentWillReceiveProps(nextProps) {
    const {year} = this.props.match.params;
    if(year !== nextProps.match.params.year) {
      this.getData(nextProps.match.params.year);
    }
 }

  getData(year) {
    this.setState({year: year});
    fetch(`https://la-leyenda.com/2018/api/videos-main/${year}`)
    .then(res => res.json())
    .then(
      (res) => {
        this.setState({data: res.videos});
      },
      (error) => {
        this.setState({error: error});
      }
    )
    return;
  }

  render() {
    const {data} = this.state;
    return(
      <LayoutLldd>
        <BannerDinamic year={this.state.year}/>
        <VideosMainMenu/>
        <div className="row videos-main">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-xs-12">
                <div className="custom">
                  {data.map((item, index) => (
                    <div>
                      <strong>
                        <span className="videos-main-title">
                          {item.title_es}
                        </span>
                      </strong>
                      <div className="embed-responsive embed-responsive-16by9">
                        { ReactHtmlParser(item.video) }
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutLldd>
    )
  }
}

export default Videos;
