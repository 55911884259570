import React, { useEffect, useState } from "react";
import Flag from "../assets/static/flag_bt.png";
import "../assets/styles/components/Top.sass";

const Top = () => {
  const [link, setLink] = useState({});

  useEffect(() => {
    const LinkSession = window.sessionStorage.getItem("menuV2");

    if (!LinkSession) {
      const LLDDLink = fetch(`https://la-leyenda.com/2018/api/enlace-personalizado/1`);
      const CaribeLink = fetch(`https://la-leyenda.com/2018/api/enlace-personalizado/2`);
      const SeriesVolcan = fetch(`https://la-leyenda.com/series-admin-temporal/api/enlace-personalizado/1`);

      Promise.all([LLDDLink, CaribeLink, SeriesVolcan]).then(async ([lldd, caribe, seriesVolcan]) => {
        const Links = {
          lldd: await lldd.json(),
          caribe: await caribe.json(),
          seriesVolcan: await seriesVolcan.json(),
        };

        setLink(Links);
        try {
          window.sessionStorage.setItem("menuV2", JSON.stringify(Links));
        } catch (error) {
          return console.log(error);
        }
      });
    }
    setLink(JSON.parse(LinkSession));
  }, []);

  return (
    <div className="row">
      <div className="col-xs-12 Top">
        <div className="hidden-lg Top-burger">
          <a id="menu_mobil" href="#my-menu">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="32px"
              height="25px"
              viewBox="0 0 32 32"
            >
              <rect x="1.599" y="1.731" fill="#000" width="28.803" height="4" />
              <rect x="1.599" y="13" fill="#000" width="28.803" height="4" />
              <rect x="1.599" y="24.268" fill="#000" width="28.803" height="4" />
            </svg>
          </a>
        </div>
        <div>
          <a href="/">
            <span className="Top-logo-lldd"></span>
          </a>
        </div>
        <div className="Top-main-menu hidden-xs hidden-sm hidden-md">
          <nav>
            <ul className="Top-menu">
              <li className="Top-lldd">
                <a href="https://la-leyenda.com/">
                  LA LEYENDA <br /> DEL DORADO
                </a>
                <div className="Top-lldd-container">
                  <div className="Top-lldd-container-wrap">
                    <b>LA LEYENDA DEL DORADO - CARRERA MTB DE 5 ETAPAS</b>
                    <div className="Top-lldd-grid">
                      <div className="Top-lldd-itema">
                        <a className="Top-lldd-link --red" href={link?.lldd?.url_es}>
                          {link?.lldd?.title_es}
                        </a>
                      </div>
                      <div className="Top-lldd-itemb">
                        <a className="Top-lldd-link" href="/contact-us">
                          CONTÁCTENOS
                        </a>
                      </div>
                      <div className="Top-lldd-itemc">
                        <p className="text-yellow">INICIO</p>
                        <ul className="Top-lldd-list">
                          <li>
                            <a href="/">- La Leyenda Resumen</a>
                          </li>
                          <li>
                            <a href="/home/packages">- Paquetes y Ascensos</a>
                          </li>
                          <li>
                            <a href="/home/prices">- Precios</a>
                          </li>
                          <li>
                            <a href="/home/payment">- Plan de pagos</a>
                          </li>
                          <li>
                            <a href="/home/categories">- Categorías</a>
                          </li>
                          <li>
                            <a href="/home/testimonials">- Testimonios</a>
                          </li>
                          <li>
                            <a href="/home/no-excuses">- No hay excusas</a>
                          </li>
                          <li>
                            <a href="/home/news">- Últimas noticias</a>
                          </li>
                        </ul>
                      </div>
                      <div className="Top-lldd-itemd">
                        <p className="text-yellow">ACERCA DE LA CARRERA</p>
                        <ul className="Top-lldd-list">
                          <li>
                            <a href="/about-us/race_overview">- Descripción de la carrera</a>
                          </li>
                          <li>
                            <a href="/about-us/packages-upgrades">- Paquetes y Ascensos</a>
                          </li>
                          <li>
                            <a href="/about-us/categories_and_prizes">- Categorías & Premios</a>
                          </li>
                          <li>
                            <a href="/about-us/charity-entries">- Registros de fundaciones</a>
                          </li>
                          <li>
                            <a href="/about-us/payment-options">- Opciones de pago</a>
                          </li>
                          <li>
                            <a href="/about-us/rules">- Reglamento</a>
                          </li>
                          <li>
                            <a href="/about-us/social-responsibility">- Responsabilidad social</a>
                          </li>
                          <li>
                            <a href="/about-us/safety_and_security">- Seguridad</a>
                          </li>
                          <li>
                            <a href="/about-us/travel">- Viajar</a>
                          </li>
                          <li>
                            <a href="/about-us/tours">- Tours</a>
                          </li>
                        </ul>
                      </div>
                      <div className="Top-lldd-iteme">
                        <p className="text-yellow">RUTA</p>
                        <ul className="Top-lldd-list">
                          <li>
                            <a href="/route/2016/summary">- 2016 Guaduas a Retiro</a>
                          </li>
                          <li>
                            <a href="/route/2017/summary">- 2017 Caldas</a>
                          </li>
                          <li>
                            <a href="/route/2018/summary">- 2018 Caldas</a>
                          </li>
                          <li>
                            <a href="/route/2019/summary">- 2019 Caldas</a>
                          </li>
                          <li>
                            <a href="/route/2022/summary">- 2022 Valle del Cauca</a>
                          </li>
                          <li>
                            <a href="/route/2023/summary">- 2023 Caldas</a>
                          </li>
                          <li>
                            <a href="/route/2024/summary">- 2024 Caldas</a>
                          </li>
                          <li>
                            <a href="/route/2025">- 2025 Información</a>
                          </li>
                        </ul>
                      </div>
                      <div className="Top-lldd-itemf">
                        <p className="text-yellow">CORREDORES</p>
                        <ul className="Top-lldd-list">
                          <li>
                            <a href="/racers/what_to_pack">- Qué empacar</a>
                          </li>
                          <li>
                            <a href="/racers/daily_schecdule">- Horario diario</a>
                          </li>
                          <li>
                            <a href="/racers/tips-and-tricks">- Consejos y trucos</a>
                          </li>
                          <li>
                            <a href="/racers/jaguares">- Jaguares</a>
                          </li>
                          <li>
                            <a href="/racers/training">- Entrenamiento</a>
                          </li>
                          <li>
                            <a href="/results">- Resultados</a>
                          </li>
                        </ul>
                      </div>
                      <div className="Top-lldd-itemg">
                        <p className="text-yellow">PATROCINADORES Y COLABORADORES</p>
                        <ul className="Top-lldd-list">
                          <li>
                            <a href="/sponsors-and-partner">- Patrocinadores y colaboradores</a>
                          </li>
                          <li>
                            <a href="/sponsors-and-partner/become-a-sponsor">- Conviértete en patrocinador</a>
                          </li>
                          <li>
                            <a href="/sponsors-and-partner/expo-information">- Información de la expo</a>
                          </li>
                        </ul>
                        <p className="mt-4 d-block text-yellow">NOTICIAS & MEDIOS</p>
                        <ul className="Top-lldd-list">
                          <li>
                            <a href="/latest-news">- Últimas noticias</a>
                          </li>
                          <li>
                            <a href="/gallery">- Galería</a>
                          </li>
                          <li>
                            <a href="/videos/2022">- Videos</a>
                          </li>
                          <li>
                            <a href="/news-and-media/press">- Prensa</a>
                          </li>
                          <li>
                            <a href="/news-and-media/newsletters">- Boletín de noticias</a>
                          </li>
                        </ul>
                      </div>
                      <div className="Top-lldd-itemh">
                        <p className="text-yellow">PREGUNTAS FRECUENTES</p>
                        <ul className="Top-lldd-list">
                          <li>
                            <a href="/faqs/race-info">- Info de la carrera</a>
                          </li>
                          <li>
                            <a href="/faqs/logistics">- Logistica</a>
                          </li>
                          <li>
                            <a href="/faqs/registration">- Registro</a>
                          </li>
                          <li>
                            <a href="/faqs/services">- Servicios</a>
                          </li>
                          <li>
                            <a href="/faqs/accommodation">- Alojamiento</a>
                          </li>
                          <li>
                            <a href="/faqs/equipment">- Equipamento</a>
                          </li>
                          <li>
                            <a href="/faqs/insurance">- Seguro</a>
                          </li>
                          <li>
                            <a href="/faqs/cancelation-refunds">- Cancelación y reembolsos</a>
                          </li>
                          <li>
                            <a href="/faqs/supporters">- Acompañantes</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="Top-caribe">
                <a href="https://la-leyenda.com/puntacana/">
                  LA LEYENDA <br /> PUNTA CANA
                </a>
                <div className="Top-caribe-container">
                  <div className="Top-caribe-container-wrap">
                    <b> LA LEYENDA PUNTA CANA - CARRERA MTB DE 5 ETAPAS - REPÚBLICA DOMINICANA</b>
                    <div className="Top-caribe-grid">
                      <div className="Top-caribe-itema">
                        <a className="Top-caribe-link" href={link?.caribe?.url_es}>
                          {link?.caribe?.title_es}
                        </a>
                      </div>
                      <div className="Top-caribe-itemb">
                        <a className="Top-caribe-link" href="https://la-leyenda.com/puntacana/contactanos">
                          CONTÁCTENOS
                        </a>
                      </div>
                      <div className="Top-caribe-itemc">
                        <p>INICIO</p>
                        <ul className="Top-caribe-list">
                          <li>
                            <a href="https://la-leyenda.com/puntacana/">- Leyenda Resumen</a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/puntacana/paquetes-y-ascensos">- Paquetes y Ascensos</a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/puntacana/precios">- Precios</a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/puntacana/categorias-y-premiacion">
                              - Categorías y Premiación
                            </a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/puntacana/rutas-y-reglamentacion/resumen">
                              - Rutas y Reglamentación
                            </a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/puntacana/patrocinio">- Patrocinio</a>
                          </li>
                        </ul>
                      </div>
                      <div className="Top-caribe-itemd"></div>
                      <div className="Top-caribe-iteme"></div>
                      <div className="Top-caribe-itemf"></div>
                      <div className="Top-caribe-itemg"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="Top-series">
                <a href="https://la-leyenda.com/series/">
                  LA LEYENDA <br /> SERIES
                </a>
                <div className="Top-series-container">
                  <div className="Top-series-container-wrap">
                    <b>LA LEYENDA SERIES - EVENTO MTB DE 1 DÍA</b>
                    <div className="Top-series-grid">
                      <div className="Top-series-itema">
                        <a className="Top-series-link" href="https://la-leyenda.com/series/">
                          INICIO
                        </a>
                      </div>
                      <div className="Top-series-itemb">
                        <a className="Top-series-link" href="https://la-leyenda.com/series/contactenos">
                          CONTACTANOS
                        </a>
                      </div>
                      <div className="Top-series-itemc">
                        <p>MANIZALES 2024</p>
                        <ul className="Top-series-list">
                          <li>
                            <a href={link?.seriesVolcan?.url_es} target="blank">
                              - {link?.seriesVolcan?.title_es}
                            </a>
                          </li>
                          <li>
                            <a href="/series/manizales/acerca-de-la-carrera">- Series Manizales resumen</a>
                          </li>
                          <li>
                            <a href="/series/manizales/precios-que-incluye">- Precios y qué incluye</a>
                          </li>
                          <li>
                            <a href="/series/manizales/carrera-manizales">- Rutas</a>
                          </li>
                          <li>
                            <a href="/series/manizales/categorias">- Categorías</a>
                          </li>
                          <li>
                            <a href="/series/manizales/premiacion-y-reglamento">- Premiación y reglamento</a>
                          </li>
                          <li>
                            <a href="/series/manizales/patrocinio-expo">- Patrocinio y expo</a>
                          </li>
                        </ul>
                      </div>
                      <div className="Top-series-itemd">
                        <p>CALDAS 2022</p>
                        <ul className="Top-series-list">
                          {/* <li><a href="https://la-leyenda.com/series/caldas/registro">- REGISTRO</a></li> */}
                          <li>
                            <a href="https://la-leyenda.com/series/caldas/acerca-de-la-carrera">
                              - Series Caldas resumen
                            </a>
                          </li>
                          {/* <li>
                            <a href="https://la-leyenda.com/series/caldas/precios-que-incluye">
                              - Precios y qué incluye
                            </a>
                          </li> */}
                          <li>
                            <a href="https://la-leyenda.com/series/caldas/categorias-y-rutas">- Categorías y rutas</a>
                          </li>
                          {/* <li>
                            <a href="https://la-leyenda.com/series/caldas/premiacion-y-reglamento">
                              - Premiación y reglamento
                            </a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/series/caldas/patrocinio-expo">
                              - Patrocinio y expo
                            </a>
                          </li> */}
                          <li>
                            <a href="https://la-leyenda.com/series/caldas/galeria">- Galería</a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/series/caldas/resultados">- Resultados</a>
                          </li>
                        </ul>
                      </div>
                      <div className="Top-series-iteme">
                        <p>CALI 2021</p>
                        <ul className="Top-series-list">
                          {/* <li><a href="https://la-leyenda.com/series/cali/registro">- REGISTRO</a></li> */}
                          <li>
                            <a href="https://la-leyenda.com/series/cali/acerca-de-la-carrera">- Series Cali resumen</a>
                          </li>
                          {/* <li>
                            <a href="https://la-leyenda.com/series/cali/precios-que-incluye">
                              - Precios y qué incluye
                            </a>
                          </li> */}
                          <li>
                            <a href="https://la-leyenda.com/series/cali/categorias-y-rutas">- Categorías y rutas</a>
                          </li>
                          {/* <li>
                            <a href="https://la-leyenda.com/series/cali/premiacion-y-reglamento">
                              - Premiación y reglamento
                            </a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/series/cali/patrocinio-expo">
                              - Patrocinio y expo
                            </a>
                          </li> */}
                          <li>
                            <a href="https://la-leyenda.com/series/cali/galeria">- Galería</a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/series/cali/resultados">- Resultados</a>
                          </li>
                        </ul>
                      </div>
                      <div className="Top-series-itemf"></div>
                      <div className="Top-series-itemg"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="Top-adventures">
                <a href="https://la-leyenda.com/adventures/">
                  LA LEYENDA <br /> ADVENTURES
                </a>
                <div className="Top-adventures-container">
                  <div className="Top-adventures-container-wrap">
                    <b>LA LEYENDA ADVENTURES - EXPERIENCIAS EN CICLISMO DE LUJO, ORGANIZADAS POR EXPERTOS</b>
                    <div className="Top-adventures-grid">
                      <div className="Top-adventures-itema">
                        <a className="Top-adventures-link" href="https://la-leyenda.com/adventures">
                          INICIO
                        </a>
                      </div>
                      <div className="Top-adventures-itemb">
                        <a className="Top-adventures-link" href="https://la-leyenda.com/adventures/contact-us">
                          CONTÁCTANOS
                        </a>
                      </div>
                      <div className="Top-adventures-itemc">
                        <a className="Top-adventures-link" href="https://la-leyenda.com/adventures/custom-tour">
                          AVENTURA PERSONALIZADO
                        </a>
                      </div>
                      <div className="Top-adventures-itemd"></div>
                      <div className="Top-adventures-iteme">
                        <p>
                          AVENTURA DEL CAFÉ <span>MTB - Pereira a Pereira</span>
                        </p>
                        <ul className="Top-adventures-list --coffee">
                          <li>
                            <a href="https://la-leyenda.com/adventures/coffee-mtb/tour-overview">- Acerca del tour</a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/adventures/coffee-mtb/itinerary/start">- Itinerario</a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/adventures/coffee-mtb/package-and-price">
                              - Paquetes y precio
                            </a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/adventures/coffee-mtb/upgrades-and-prices">
                              - Ascensos y precios
                            </a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/adventures/coffee-mtb/hotels">- Hoteles</a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/adventures/coffee-mtb/travel-details">
                              - Detalles del viaje
                            </a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/adventures/coffee-mtb/important-info">
                              - Información importante
                            </a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/adventures/booknow/coffee-mtb">- RESERVAR AHORA</a>
                          </li>
                        </ul>
                      </div>
                      <div className="Top-adventures-itemf">
                        <p>
                          AVENTURA ANTIOQUIA <span>MTB - Medellin a Medellin</span>
                        </p>
                        <ul className="Top-adventures-list --antioquia">
                          <li>
                            <a href="https://la-leyenda.com/adventures/antioquia-mtb/tour-overview">
                              - Acerca del tour
                            </a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/adventures/antioquia-mtb/itinerary/start">- Itinerario</a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/adventures/antioquia-mtb/package-and-price">
                              - Paquetes y precio
                            </a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/adventures/antioquia-mtb/upgrades-and-prices">
                              - Ascensos y precios
                            </a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/adventures/antioquia-mtb/hotels">- Hoteles</a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/adventures/antioquia-mtb/travel-details">
                              - Detalles del viaje
                            </a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/adventures/antioquia-mtb/important-info">
                              - Información importante
                            </a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/adventures/booknow/antioquia-mtb">- RESERVAR AHORA</a>
                          </li>
                        </ul>
                      </div>
                      <div className="Top-adventures-itemg">
                        <p>
                          AVENTURA VOLCÁN <span>MTB - Bogotá a Pereira</span>
                        </p>
                        <ul className="Top-adventures-list --volcano">
                          <li>
                            <a href="https://la-leyenda.com/adventures/volcano-mtb/tour-overview">- Acerca del tour</a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/adventures/volcano-mtb/itinerary/start">- Itinerario</a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/adventures/volcano-mtb/package-and-price">
                              - Paquetes y precio
                            </a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/adventures/volcano-mtb/upgrades-and-prices">
                              - Ascensos y precios
                            </a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/adventures/volcano-mtb/hotels">- Hoteles</a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/adventures/volcano-mtb/travel-details">
                              - Detalles del viaje
                            </a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/adventures/volcano-mtb/important-info">
                              - Información importante
                            </a>
                          </li>
                          <li>
                            <a href="https://la-leyenda.com/adventures/booknow/volcano-mtb">- RESERVAR AHORA</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </nav>
        </div>
        <div className="Top-redes">
          <a href="https://la-leyenda.com/en">
            <img className="img-responsive" src={Flag} alt="Idioma Español" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Top);
