import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";

import LayoutLldd from "../components/LayoutLldd";
import HomeMenu from "../components/HomeMenu";
import Banner from "../components/Banner";
import HorizontalFormContact from "../components/HorizontalFormContact";
import Carousel from "../components/Carousel";

import setInputValue from "../../src/hooks/useInputValue";

import "../assets/styles/containers/HomePrices.sass";

const HomePrices = () => {
  const [prices, setPrices] = useState({});
  const [aditions, setAditions] = useState({});
  const [gallery, setGallery] = useState([]);
  const [totalAmount, setTotalAmount] = useState("");
  const amount = setInputValue("");
  const currency = setInputValue("EUR");

  useEffect(() => {
    fetch(`https://la-leyenda.com/2018/api/general/home-prices`)
      .then((res) => res.json())
      .then((res) => {
        setPrices(res.prices);
        setAditions(res.aditions);
        setGallery(res.photos.filter((item) => item.languaje === "es"));
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const URL = `https://data.fixer.io/api/convert?access_key=${process.env.REACT_APP_FIXER_CURRENCY}&from=COP&to=${currency.value}&amount=${amount.value}`;

    fetch(URL)
      .then((res) => res.json())
      .then((res) => {
        let result = Number.parseFloat(res.result).toFixed(2);
        setTotalAmount(new Intl.NumberFormat().format(result));
      });
  };

  return (
    <LayoutLldd title={prices.seo_title_es} description={prices.seo_content_es}>
      <Banner />
      <HorizontalFormContact />
      <HomeMenu />
      <div className="row no-gutters home-prices contenido_min">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-xs-12">
              {ReactHtmlParser(prices.content_es)}
            </div>
          </div>
        </div>

        <div className="col-xs-12 background-dark">
          <div className="row">
            <div className="container">
              <div className="row no-gutters">
                <div className="col-xs-12">
                  <form
                    className="payment-form-prices"
                    onSubmit={(e) => handleSubmit(e)}
                  >
                    <div className="payment-form-prices__container">
                      <span>Valor COP:</span>
                      <input type="text" id="amount" {...amount} />
                    </div>

                    <div className="payment-form-prices__container">
                      <span>De:</span>
                      <input type="text" value="COP" readOnly />
                    </div>

                    <div className="payment-form-prices__icon">
                      <i className="fas fa-long-arrow-alt-right"></i>
                    </div>

                    <div className="payment-form-prices__container">
                      <span>A:</span>
                      <select name="" id="installments" {...currency}>
                        <option value="USD">USD</option>
                        <option value="EUR">EUR</option>
                        <option value="GBP">GBP</option>
                        <option value="AUD">AUD</option>
                        <option value="CAD">CAD</option>
                        <option value="JPY">JPY</option>
                        <option value="BRL">BRL</option>
                        <option value="ARS">ARS</option>
                        <option value="CLP">CLP</option>
                        <option value="ZAR">ZAR</option>
                      </select>
                    </div>

                    <div className="payment-form-prices__button">
                      <button type="submit">
                        <i className="fas fa-angle-right"></i>
                      </button>
                    </div>

                    <div className="payment-form-prices__container">
                      <span>Valor:</span>
                      <input
                        type="text"
                        id="resultAmount"
                        value={totalAmount}
                        readOnly
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row no-gutters">
            <div className="col-xs-12 --promotions">
              {ReactHtmlParser(aditions.content_es)}
            </div>
          </div>
        </div>

        <div className="col-xs-12 price-gallery no-padding">
          <Carousel images={gallery} />
        </div>
      </div>
    </LayoutLldd>
  );
};

export default HomePrices;
