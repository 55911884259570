import React from 'react';
import '../assets/styles/components/Carousel.sass';

class CarouselTwo extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      tisOpen: false,
      timageIndex: null,
      tcarouselIndex: 0,
      twidth: 0,
      titems: 3
    }

    this.handleWindowSize = this.handleWindowSize.bind(this);
  }

  galleryState(index) {
    this.setState({
      tisOpen: true,
      timageIndex: index
    })
  }

  handleCloseModal() {
    this.setState({
      tisOpen: false,
      timageIndex: null
    })
  }

  handleCloseModalClickOutside(e) {
    const modal__wraper =  document.getElementById('modal__wraper');
    const modal__nav =  document.getElementById('modal__nav');
    
    let isClickInsideWrapper = modal__wraper.contains(e.target);
    let isClickInsideNav = modal__nav.contains(e.target);
    if (!isClickInsideWrapper && !isClickInsideNav) {
      this.setState({
        tisOpen: false,
        timageIndex: null
      })
    }
  }

  handleKeyDown = e => {
    if (e.keyCode === 27) {
      this.setState({
        tisOpen: false,
        timageIndex: null
      })
    }
  };

  handleNext(e) {
    e.preventDefault();
    const { images } = this.props;
    const { timageIndex } = this.state;
    const url = `https://la-leyenda.com/2018/en/img/gallery_home/large/${images[(timageIndex + images.length + 1) % images.length].photo}`;
    this.setState({
      timageIndex: (timageIndex + images.length + 1) % images.length
    })
    document.getElementById('imageLarge').src = url;
  }

  handleAfter(e) {
    e.preventDefault();
    const { images } = this.props;
    const { timageIndex } = this.state;
    const url = `https://la-leyenda.com/2018/en/img/gallery_home/large/${images[(timageIndex + images.length - 1) % images.length].photo}`;
    this.setState({
      timageIndex: (timageIndex + images.length - 1) % images.length
    })
    document.getElementById('imageLarge').src = url;
  }
  
  handleCarouselPrev(e) {
    e.preventDefault();
    if (this.state.tcarouselIndex > 0)
    {
      this.setState(
        {tcarouselIndex: this.state.tcarouselIndex - 1},
        () => {
          let translate = this.state.tcarouselIndex * this.state.twidth;
          document.getElementById('carousel-list-two').style.transform = `translateX(-${translate}px)`;
        }
      )
    }
  }

  handleCarouselNext(e) {
    e.preventDefault();
    this.setState(
      {tcarouselIndex: this.state.tcarouselIndex + 1},
      () => {
        let translate = this.state.tcarouselIndex * this.state.twidth;
        document.getElementById('carousel-list-two').style.transform = `translateX(-${translate}px)`;
      }
    )
  }

  componentDidMount() {
    const ancho = window.innerWidth;
    if (ancho <= 575) {
      this.setState({
        twidth: document.querySelector('.carousel-container').offsetWidth
      })
    } else {
      this.setState({
        twidth: document.querySelector('.carousel-container').offsetWidth / 3
      })
    }
    window.addEventListener('resize', this.handleWindowSize);
    document.addEventListener("keydown", this.handleKeyDown, false);
  }

  componentWillUnmount() {
    window.addEventListener('resize', this.handleWindowSize);
    document.removeEventListener("keydown", this.handleKeyDown, false);
  }

  handleWindowSize() {
    const ancho = window.innerWidth;
    let translate = '';
    if (ancho <= 575) {
      this.setState({
        twidth: document.querySelector('.carousel-container').offsetWidth
      })
      translate = this.state.tcarouselIndex * ancho;
    } else {
      this.setState({
        twidth: document.querySelector('.carousel-container').offsetWidth / 3
      })
      translate = this.state.tcarouselIndex * ancho / 3;
    }
    document.getElementById('carousel-list-two').style.transform = `translateX(-${translate}px)`;
  }

  render() {
    const { images } = this.props;
    const { tisOpen, timageIndex } = this.state;

    return(
      <>
        <div className="carousel-container">
          <ul className="carousel-container__list" id="carousel-list-two">
            {images.map((item, i) => (
              <li key={i} className="carousel-container__list--item">
                <button type="button" onClick={() => this.galleryState(i)}>
                  <img src={`https://la-leyenda.com/2018/en/img/gallery_home/${item.photo}`} alt=""/>
                  <span></span>
                </button>
              </li>
            ))}
          </ul>
          
          {this.state.tcarouselIndex > 0 &&
            <a href="#after" className="carousel-container__list--left" onClick={(e) => this.handleCarouselPrev(e)}>
              <img src="https://la-leyenda.com/img/arrow_left.png" alt="Regresar una foto"/>
            </a>
          }
        
          {this.state.tcarouselIndex + this.state.titems < images.length &&
            <a href="#before" className="carousel-container__list--right" onClick={(e) => this.handleCarouselNext(e)}>
              <img src="https://la-leyenda.com/img/arrow_right.png"  alt="Adelantar una foto"/>
            </a>
          }
           
        </div>

        {tisOpen &&
          <div className="carousel-modal" onClick={(e) => this.handleCloseModalClickOutside(e)}>

            <div id="modal__wraper" className="gallery-modal__wraper">
              <button className="gallery-modal__wraper--btn" onClick={() => this.handleCloseModal()}>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" className="svg-inline--fa fa-times fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                  <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
                </svg>
              </button>

              <div className="gallery-modal__wraper--content">
                <img id="imageLarge" src={`https://la-leyenda.com/2018/en/img/gallery_home/large/${images[timageIndex].photo}`} alt=""/>
              </div>
            </div>

            <nav id="modal__nav" className="gallery-modal__nav">
              <ul>
                <li>
                  <a href="#after" onClick={(e) => this.handleAfter(e)}>
                    <img src="https://la-leyenda.com/img/arrow_left.png" alt="Regresar una foto"/>
                  </a>
                </li>
                <li>
                  <a href="#next" onClick={(e) => this.handleNext(e)}>
                    <img src="https://la-leyenda.com/img/arrow_right.png"  alt="Adelantar una foto"/>
                  </a>
                </li>
              </ul>
            </nav>

          </div>
        }

      </>
    )
  }
}

export default CarouselTwo;
