import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

import LayoutLldd from '../components/LayoutLldd';
import HomeMenu from '../components/HomeMenu';
import Banner from '../components/Banner';
import HorizontalFormContact from '../components/HorizontalFormContact';
import Carousel from '../components/Carousel';
import CarouselTwo from '../components/CarouselTwo';

import '../assets/styles/containers/HomePackages.sass';

const HomePackages = () => {

  const [racerTitle, setRacerTitle] = useState({});
  const [racerPackage, setRacerPackage] = useState({});
  const [supporterTitle, setSupporterTitle] = useState({});
  const [supporterPackage, setSupporterPackage] = useState({});
  const [galleryRacer, setGalleryRacer] = useState([]);
  const [gallerySupporter, setGallerySupporter] = useState([]);

  useEffect(() => {
    fetch(`https://la-leyenda.com/2018/api/general/home-packages`)
    .then(res => res.json())
    .then((res) => {
        setRacerTitle(res.racerTitle);
        setRacerPackage(res.racerPackage);
        setSupporterTitle(res.supporterTitle);
        setSupporterPackage(res.supporterPackage);
        setGalleryRacer(res.photosRacer.filter((item) => item.languaje === 'es'));
        setGallerySupporter(res.photosSupporter.filter((item) => item.languaje === 'es'));
      }
    )
  }, []);
  
  return(
    <LayoutLldd
      title={racerTitle.seo_title_es}
      description={racerTitle.seo_content_es}
    >
      <Banner/>
      <HorizontalFormContact />
      <HomeMenu />
      <div className="row row-no-gutters package contenido_min">

        <div className="container package__top">
          <div className="row no-gutters">
            <header className="col-xs-12">
              { ReactHtmlParser(racerTitle.content_es) }
            </header>
          </div>
        </div>

        <div className="col-xs-12 package__gallery--racers no-padding">
          <Carousel images={galleryRacer}/>
        </div>

        <div className="container">
          <div className="row no-gutters">
            <div className="col-xs-12">
              <div className="package__optios">
                { ReactHtmlParser(racerPackage.content_es) }
              </div>
            </div>
          </div>
        </div>

        <div className="col-xs-12 package__gallery--racers no-padding">
          { ReactHtmlParser(supporterTitle.content_es) }
          <CarouselTwo images={gallerySupporter}/>
        </div>

        <div className="container">
          <div className="row no-gutters">
            <div className="col-xs-12">
              { ReactHtmlParser(supporterPackage.content_es) }
            </div>
          </div>
        </div>

      </div>
    </LayoutLldd>
  )
}

export default HomePackages;
