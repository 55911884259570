import React from 'react';
import {Link} from 'react-router-dom';
import '../assets/styles/components/StageMenu.sass';

const SpectatorStageMenu = () => (
  <div className="row stage-menu">
    <div className="container">
      <div className="row">
        <div className="col-xs-12">
          <ul className="stage-menu__list">
            <li className="stage-menu__list--item">
              <Link id="spectatorExpo" to="/home-carrera/spectator-expo">
                EXPO
              </Link>
            </li>
            <li className="stage-menu__list--item">
              <Link id="spectatorPrologo" to="/home-carrera/spectator-prologue">
                PRÓLOGO
              </Link>
            </li>
            <li className="stage-menu__list--item">
              <Link id="spectatorEtapa1" to="/home-carrera/spectator-stage-one">
                ETAPA 1
              </Link>
            </li>
            <li className="stage-menu__list--item">
              <Link id="spectatorEtapa2" to="/home-carrera/spectator-stage-two">
                ETAPA 2
              </Link>
            </li>
            <li className="stage-menu__list--item">
              <Link id="spectatorEtapa3" to="/home-carrera/spectator-stage-three">
                ETAPA 3
              </Link>
            </li>
            <li className="stage-menu__list--item">
              <Link id="spectatorEtapa4" to="/home-carrera/spectator-stage-four">
                ETAPA 4
              </Link>
            </li>
            <li className="stage-menu__list--item">
              <Link id="spectatorEtapa5" to="/home-carrera/spectator-stage-five">
                ETAPA 5
              </Link>
            </li>
            <li className="stage-menu__list--item">
              <Link id="spectatorEtapa6" to="/home-carrera/spectator-stage-six">
                ETAPA 6
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
)
  
export default SpectatorStageMenu;