import React, { useEffect, useState } from "react";
import setInputValue from "../hooks/useInputValue";
import Logo from "../assets/static/icono_negro.png";
import "../assets/styles/components/Modal.sass";

const Modal = (props) => {
  let formButton = document.getElementById("button-register");
  let formInputs = document.getElementById("register-form");
  const name = setInputValue("");
  const email = setInputValue("");
  const language = setInputValue("");

  useEffect(() => {
    formButton = document.getElementById("button-register");
    formInputs = document.getElementById("register-form");
  }, []);

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    if (name.value == "" || email.value == "" || language.value == "") {
      formButton.innerHTML = "INGRESE INFORMACIÓN";
      const inputs = formInputs.elements;

      if (name.value == "") inputs["name"].classList.add("btn-fill");
      if (email.value == "") inputs["email"].classList.add("btn-fill");
      if (language.value == "") inputs["language"].classList.add("btn-fill");
      formButton.classList.add("btn-fill");
      validate();
    } else {
      sendForm();
    }
  };

  const sendForm = async () => {
    const formData = new FormData();
    formData.append("name", name.value);
    formData.append("email", email.value);
    formData.append("language_selected", language.value);
    formData.append("languaje", "es");
    formData.append("section", "newsletter");
    fetch(`${process.env.REACT_APP_URL}/newsletter`, {
      method: "POST",
      body: formData,
    });

    const dataEmail = {
      email: email.value,
    };

    fetch(`${process.env.REACT_APP_SEND_EMAIL_HORIZONTAL}/newsletter`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataEmail),
    }).catch((error) => {
      console.log(error);
    });

    gracias();
  };

  const gracias = () => {
    formButton.innerHTML = "GRACIAS";
    formButton.classList.add("btn-thanks");
    resetForm();
  };

  const resetForm = () => {
    setTimeout(() => {
      const inputs = formInputs.elements;

      formButton.innerHTML = "ENVIAR";
      formButton.classList.remove("btn-thanks");
      inputs["name"].value = "";
      inputs["email"].value = "";
      inputs["language"].value = "";
    }, 3000);
  };

  const validate = () => {
    setTimeout(() => {
      const inputs = formInputs.elements;

      formButton.innerHTML = "ENVIAR";
      formButton.classList.remove("btn-fill");
      inputs["name"].classList.remove("btn-fill");
      inputs["email"].classList.remove("btn-fill");
      inputs["language"].classList.remove("btn-fill");
    }, 3000);
  };

  const handleCloseModalClickOutside = (e) => {
    const modal__wraper = document.getElementById("Modal-wrapper");

    let isClickInsideWrapper = modal__wraper.contains(e.target);
    if (!isClickInsideWrapper) {
      props.close();
    }
  };

  return (
    <div className="Modal" onClick={(e) => handleCloseModalClickOutside(e)}>
      <div className="Modal-wrapper" id="Modal-wrapper">
        <div className="Modal-wrapper-header">
          <button onClick={props.close}>X</button>
        </div>
        <div className="Modal-wrapper-body">
          <img src={Logo} alt="La Leyenda del Dorado" />
          <h2>SEA EL PRIMERO</h2>
          <p>Regístrese para recibir las últimas noticias e información exclusiva.</p>
          <form className="register-form" id="register-form" onSubmit={(e) => handleOnSubmit(e)}>
            <input type="text" name="name" placeholder="NOMBRE*" {...name} />
            <input type="email" name="email" placeholder="EMAIL*" {...email} />
            <select name="language" {...language}>
              <option value="">IDIOMA*</option>
              <option value="Español">Español</option>
              <option value="Ingles">English</option>
              <option value="Portugues">Português</option>
            </select>
            <button id="button-register" type="submit">
              REGÍSTRESE
            </button>
          </form>
          <p>
            Al registrarse, acepta{" "}
            <a className="black-link" href="/documents/privacy_policy.pdf" target="_blank">
              la política de privacidad
            </a>{" "}
            y{" "}
            <a className="black-link" href="/documents/terms_of_use.pdf" target="_blank">
              los términos de uso
            </a>{" "}
            de La Leyenda.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Modal;
