import React, {Component} from 'react';
import Table from '../components/Table';
import TableIndividual from '../components/TableIndividual';
import Layout from '../components/Layout';
import '../assets/styles/components/Results.sass';

class Results extends Component{
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      segments: [],
      categories: [],
      racers: [],
      racers_finishers: [],
      racers_nofinishers: [],
      racers_view: [],
      form_segment: '',
      form_category: '',
      form_search: ''
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    fetch('https://la-leyenda.com/2018/api/results')
    .then(res => res.json())
    .then(res => {
      this.setState({
        segments: res.segments.reverse(),
        form_segment: res.segments[0].segmento_es,
        racers: res.segments[0].results.racers,
        racers_finishers: res.segments[0].results.finishers,
        racers_nofinishers: res.segments[0].results.nofinishers,
        categories: res.categories
      })
    })

    document.getElementById('results').classList.add('active')
  }

  setResultsState(data) {
    const { segments } = this.state
    const segment = segments.find(item => item.segmento_es === data)
    return Promise.resolve(
      this.setState({
        racers: segment.results.racers,
        racers_finishers: segment.results.finishers,
        racers_nofinishers: segment.results.nofinishers,
        form_segment: data
      })
    )
  }

  setCategoryState(category) {
    const { racers, racers_finishers, racers_nofinishers } = this.state
    return Promise.resolve(
      this.setState({
        racers: racers.filter(racer => racer.categoria_es === category),
        racers_finishers: racers_finishers.filter(racer => racer.categoria_es === category),
        racers_nofinishers: racers_nofinishers.filter(racer => racer.categoria_es === category),
        form_category: category
      })
    )
  }

  setSearchState(keyword) {
    const { racers, racers_finishers, racers_nofinishers } = this.state
    return Promise.resolve(
      this.setState({
        racers: racers.filter(racer => racer.nombre_corredor_1.includes(keyword.toUpperCase()) || racer.nombre_corredor_2.includes(keyword.toUpperCase())),
        racers_finishers: racers_finishers.filter(racer => racer.nombre.includes(keyword.toUpperCase())),
        racers_nofinishers: racers_nofinishers.filter(racer => racer.nombre.includes(keyword.toUpperCase())),
        form_search: keyword
      })
    )
  }

  async handleSubmit(event) {
    event.preventDefault();
    let target = event.target

    try {
      await this.setResultsState(target.resultados.value)
      if(target.categorias.value) {
        await this.setCategoryState(target.categorias.value)
      }
      if(target.search_racer.value) {
        await this.setSearchState(target.search_racer.value)
      }
    } catch (error) {
      console.log(error)
    }
  }

  componentWillUnmount() {
    document.getElementById('results').classList.remove('active')
  }

  render(){
    const { error, segments, categories, racers, racers_finishers, racers_nofinishers } = this.state;

    return(
      <Layout>
        <div className="row">
          <div className="container contenido_min">
            <div className="row">
              <form onSubmit={this.handleSubmit} className="form-horizontal results-form">
  
                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 text-left">
                  <div className="form-group">
                    <label htmlFor="resultados" className="col-sm-4 ptop">Resultados</label>
                    <div className="col-sm-8">
                      <select name="resultados" id="resultados" className="form-control">
                        {segments.map( (segment, i) => (
                          <option key={i} value={segment.segmento_es}>{segment.segmento_es}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
  
                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 text-left">
                  <div className="form-group">
                    <label htmlFor="categorias" className="col-sm-4 ptop">Categoría</label>
                    <div className="col-sm-8">
                      <select name="categorias" id="categorias" className="form-control">
                        <option value="">TODAS</option>
                        {categories.map( (categorie, i) => (
                          <option key={i} value={categorie.categoria_es}>{categorie.categoria_es}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
  
                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 text-left">
                  <div className="form-group">
                    <label htmlFor="year" className="col-sm-4 control-label-left">Buscar corredores</label>
                    <div className="col-sm-8">
                      <input className="form-control" type="text" name="search_racer"/>
                    </div>
                  </div>
                </div>
  
                <div className="col-xs-12 text-right">
                  <button className="results-form__btn" type="submit">VER RESULTADOS</button>
                </div>
  
              </form>
            </div>
            
            <div className="row">
              <div className="col-xs-12">
                <h1>2019 - {this.state.form_segment} {this.state.form_category ? ` - ${this.state.form_category}` : '' } </h1>
                <p>{this.state.form_search ? `Búsqueda: ${this.state.form_search}` : '' }  </p>
              </div>
            </div>
            
            <Table racers={racers} />

            {racers_finishers.length > 0 &&
              <>
                <div className="row">
                  <div className="col-xs-12">
                    <h1>2019 - {this.state.form_segment} {this.state.form_category ? ` - ${this.state.form_category}` : '' } - finalizador individual</h1>
                  </div>
                </div>
                
                <TableIndividual racers={racers_finishers} />
              </>
            }

            {racers_nofinishers.length > 0 &&
              <>
                <div className="row">
                  <div className="col-xs-12">
                    <h1>2019 - {this.state.form_segment} {this.state.form_category ? ` - ${this.state.form_category}` : '' } - no finalizador</h1>
                  </div>
                </div>
                
                <TableIndividual racers={racers_nofinishers} />
              </>
            }

          </div>
        </div>
      </Layout>
    );
  }

}

export default Results;