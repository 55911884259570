import React from 'react'
import Top from './Top'
import Footer from './Footer'
import Sponsors from './Sponsors'
import { Helmet } from 'react-helmet'

const LayoutLldd = (props) => (
    <>
        <Helmet>
            <title>{props.title ? props.title : 'La Leyenda del Dorado'}</title>
            <meta name="description" content={props.description ? props.description : 'La Leyenda del Dorado, la carrera por etapas de mountain bike más prestigiosa de Sudamérica donde ciclistas profesionales y aficionados de todo el mundo compiten en las majestuosas montañas de Colombia.'} />
        </Helmet>
        <div className="container-fluid">
            <Top/>
            {props.children}
            <Sponsors/>
            <Footer/>
        </div>
    </>
)

export default LayoutLldd;