import React from 'react';
import { NavLink } from 'react-router-dom';
import '../assets/styles/components/RoutesMenu.sass';

const RoutesMenu = ({year}) => (
  <div className="row routes-menu">
    <div className="container">
      <div className="row">
        <div className="col-xs-12">
          <ul className="routes-menu__list">
            <li className="routes-menu__list--item">
              <NavLink id="stageResumen" to={`/route/${year}/summary`}>
                RESUMEN
              </NavLink>
            </li>
            <li className="routes-menu__list--item">
              <NavLink id="stagePrologo" to={`/route/${year}/prologue`}>
                PRÓLOGO
              </NavLink>
            </li>
            <li className="routes-menu__list--item">
              <NavLink id="stageEtapa1" to={`/route/${year}/stage-one`}>
                ETAPA 1
              </NavLink>
            </li>
            <li className="routes-menu__list--item">
              <NavLink id="stageEtapa2" to={`/route/${year}/stage-two`}>
                ETAPA 2
              </NavLink>
            </li>
            <li className="routes-menu__list--item">
              <NavLink id="stageEtapa3" to={`/route/${year}/stage-three`}>
                ETAPA 3
              </NavLink>
            </li>
            <li className="routes-menu__list--item">
              <NavLink id="stageEtapa4" to={`/route/${year}/stage-four`}>
                ETAPA 4
              </NavLink>
            </li>
            <li className="routes-menu__list--item">
              <NavLink id="stageEtapa5" to={`/route/${year}/stage-five`}>
                ETAPA 5
              </NavLink>
            </li>
            <li className="routes-menu__list--item">
              <NavLink id="stageEtapa6" to={`/route/${year}/stage-six`}>
                ETAPA 6
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
)
  
export default RoutesMenu;