import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import LayoutLldd from '../components/LayoutLldd';
import BannerInternal from '../components/BannerInternal';

import '../assets/styles/containers/SimpleSection.sass';

class SimpleSection extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      data: [],
    }
  }
  
  componentDidMount() {
    const {slug} = this.props.match.params;
    this.getData(slug);
  }

  componentWillReceiveProps(nextProps) {
    const {slug} = this.props.match.params;
    if(slug !== nextProps.match.params.slug) {
      this.getData(nextProps.match.params.slug);
    }
 }

  getData(slug) {
    fetch(`https://la-leyenda.com/2018/api/general/${slug}`)
    .then(res => res.json())
    .then(
      (res) => {
        this.setState({data: res});
      },
      (error) => {
        this.setState({error: error});
      }
    )
    return;
  }
    
  render() {
    const {data} = this.state;
    const {banner, content_es} = data;

    return(
      <LayoutLldd
        title={data.seo_title_es}
        description={data.seo_content_es}
      >
        <BannerInternal image={banner} />
        <div className="row">
          <div className="container">
            <div className="row no-gutters">

              { ReactHtmlParser(content_es) }

            </div>
          </div>
        </div>
      </LayoutLldd>
    )
  }
}
  
export default SimpleSection;
