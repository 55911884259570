import React from 'react';
import Layout from '../components/Layout';
import StageMenu from '../components/StageMenu';
import '../assets/styles/components/Stages.sass';

class HomeCarrera extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            content: '',
            title: ''
        }
    }
    
    componentDidMount() {
        
        fetch('https://la-leyenda.com/2018/api/stage/34')
        .then(res => res.json())
        .then(
        (res) => {
            this.setState({
                isLoaded: true,
                content: res.content_es,
                title: res.title_es
            });

            const CONTAINER = document.getElementById('contenido');
            if (CONTAINER) {
                CONTAINER.innerHTML = this.state.content
            }
        },
        
        (error) => {
            this.setState({
                isLoaded: true,
                error
            });
        }
        )

        document.getElementById('stageResumen').classList.add('active')
        document.getElementById('stages').classList.add('active')
    }

    componentWillUnmount() {
        document.getElementById('stageResumen').classList.remove('active')
        document.getElementById('stages').classList.remove('active')
    }

    render() {
        return(
            <Layout>
                <StageMenu/>
                <div className="row">
                    <div className="container">
                        <div className="row" id="contenido"></div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default HomeCarrera;