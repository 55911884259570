import React from 'react'
import Top from './Top'
import Banner from './Banner'
import Menu from './Menu'
import Footer from './Footer'
import Sponsors from './Sponsors'
import Leaders from './Leaders'

import '../assets/styles/global.sass'

const Layout = ({children}) => (
    <>
        <div className="container-fluid">
            <Top/>
            <Banner/>
            <Leaders/>
            <Menu/>
            {children}
            <Sponsors/>
            <Footer/>
        </div>
    </>
)

export default Layout;