import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

import LayoutLldd from '../components/LayoutLldd';
import BannerInternal from '../components/BannerInternal';
import setInputValue from '../hooks/useInputValue';

import '../assets/styles/containers/FindPartner.sass';

const FindPartner = () => {
  let formButton = document.getElementById('button-partner');
  let formInputs = document.getElementById('partner-form');
  const name = setInputValue('');
  const email = setInputValue('');
  const phone = setInputValue('');
  const age = setInputValue('');
  const nationality = setInputValue('');
  const city = setInputValue('');
  const languages = setInputValue('');
  const race_aim = setInputValue('');
  const package_selected = setInputValue('');
  const experience = setInputValue('');
  const [data, setData] = useState([]);
  
  useEffect(() => {
    formButton = document.getElementById('button-partner');
    formInputs = document.getElementById('partner-form');

    fetch(`${process.env.REACT_APP_URL}/general-by-id/56`)
    .then(res => res.json())
    .then((res) => {
        setData(res);
      }
    )
  }, [])

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    if (
        name.value == '' ||
        email.value == '' ||
        phone.value == '' ||
        age.value == '' ||
        nationality.value == '' ||
        city.value == '' ||
        languages.value == '' ||
        race_aim.value == '' ||
        package_selected.value == '' ||
        experience.value == ''
      ) {
      formButton.innerHTML = "INGRESE INFORMACIÓN";
      const inputs = formInputs.elements;

      if (name.value == '') inputs['name'].classList.add('btn-fill')
      if (email.value == '') inputs['email'].classList.add('btn-fill')
      if (phone.value == '') inputs['phone'].classList.add('btn-fill')
      if (age.value == '') inputs['age'].classList.add('btn-fill')
      if (nationality.value == '') inputs['nationality'].classList.add('btn-fill')
      if (city.value == '') inputs['city'].classList.add('btn-fill')
      if (languages.value == '') inputs['languages'].classList.add('btn-fill')
      if (race_aim.value == '') inputs['race_aim'].classList.add('btn-fill')
      if (package_selected.value == '') inputs['package_selected'].classList.add('btn-fill')
      if (experience.value == '') inputs['experience'].classList.add('btn-fill')
      formButton.classList.add('btn-fill');
      validate();
    } else {
      sendForm();
    }
  }

  const sendForm = async () => {
    const formData = new FormData();
    formData.append('name', name.value);
    formData.append('email', email.value);
    formData.append('phone', phone.value);
    formData.append('age', age.value);
    formData.append('nationality', nationality.value);
    formData.append('city', city.value);
    formData.append('languages', languages.value);
    formData.append('race_aim', race_aim.value);
    formData.append('package_selected', package_selected.value);
    formData.append('experience', experience.value);
    formData.append('languaje', 'es');
    formData.append('section', 'findPartner');
    const result = await fetch(`${process.env.REACT_APP_URL}/find-partner`,
      {
        method: 'POST',
        body: formData
      });
    gracias();
  }

  const gracias = () => {
    formButton.innerHTML = "GRACIAS";
    formButton.classList.add('btn-thanks')
    resetForm();
  }

  const resetForm = () => {
    setTimeout(() => {
      const inputs = formInputs.elements;

      formButton.innerHTML = "ENVIAR";
      formButton.classList.remove('btn-thanks');
      inputs['name'].value = '';
      inputs['email'].value = '';
      inputs['phone'].value = '';
      inputs['age'].value = '';
      inputs['nationality'].value = '';
      inputs['city'].value = '';
      inputs['languages'].value = '';
      inputs['race_aim'].value = '';
      inputs['package_selected'].value = '';
      inputs['experience'].value = '';
    }, 3000)

  }

  const validate = () => {
    setTimeout(() => {
      const inputs = formInputs.elements;

      formButton.innerHTML = "ENVIAR";
      formButton.classList.remove('btn-fill');
      inputs['name'].classList.remove('btn-fill');
      inputs['email'].classList.remove('btn-fill');
      inputs['phone'].classList.remove('btn-fill');
      inputs['age'].classList.remove('btn-fill');
      inputs['nationality'].classList.remove('btn-fill');
      inputs['city'].classList.remove('btn-fill');
      inputs['languages'].classList.remove('btn-fill');
      inputs['race_aim'].classList.remove('btn-fill');
      inputs['package_selected'].classList.remove('btn-fill');
      inputs['experience'].classList.remove('btn-fill');
    }, 3000)

  }

  console.log('data', data);

  return(
    <LayoutLldd
      title={data.seo_title_es}
      description={data.seo_content_es}
    >
      <BannerInternal image="https://la-leyenda.com/img/banners_contenido/find_a_partner.jpg" />
      <div className="row whats-included">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-xs-12 text-justify">
              { ReactHtmlParser(data.content_es) }
            </div>
          </div>

          <div className="row no-gutters">

            <div className="col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 col-lg-8 col-lg-offset-2">
              <form className="contact-form-partner" id="partner-form" onSubmit={e => handleOnSubmit(e)}>
                <input type="text" name="name" placeholder="NOMBRE*" {...name}/>
                <input type="email" name="email" placeholder="EMAIL*" {...email}/>
                <input type="text" name="phone" placeholder="NUMERO DE CONTACTO*" {...phone}/>
                <input type="text" name="age" placeholder="EDAD*" {...age}/>
                <input type="text" name="nationality" placeholder="NACIONALIDAD*" {...nationality}/>
                <input type="text" name="city" placeholder="CIUDAD*" {...city}/>
                <input type="text" name="languages" placeholder="IDIOMAS HABLADOS*" {...languages}/>
                <input type="text" name="race_aim" placeholder="OBJETIVO DE LA CARRERA*" {...race_aim}/>
                <select name="package_selected" {...package_selected}>
                  <option value="">PAQUETE*</option>
                  <option value="Nevado">Nevado (Hotel y comida)</option>
                  <option value="Altiplano">Altiplano (Solo carrera)</option>
                  <option value="Nevado o Altiplano">Nevado o Altiplano</option>
                </select>
                <textarea name="experience" placeholder="EXPERIENCIA*" cols="30" rows="10" {...experience}></textarea>
                <button id="button-partner" type="submit">ENVIAR</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </LayoutLldd>
  )
}

export default FindPartner;
