import React, { useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';

import LayoutLldd from '../components/LayoutLldd';
import GalleryGeneral from '../components/GalleryGeneral';
import BannerInternal from '../components/BannerInternal';
import '../assets/styles/containers/GalleryDetail.sass';

const GalleryDetail = () => {
  const [title, setTitle] = useState([]);
  const [pictures, setPictures] = useState([]);
  const { id, slug } = useParams();
  
  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/gallery/${id}/${slug}`)
    .then(res => res.json())
    .then(
      (res) => {
        setTitle(res.title);
        setPictures(res.pictures);
    })
  }, []);

  return(
    <LayoutLldd>
      <BannerInternal image="https://la-leyenda.com/2018/en/img/banners_contenido/gallery_int.jpg" />
      <div className="row gallery-detail">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-xs-12 text-center">
              <h1 className="gallery-detail__title">{title.title_es}</h1>
            </div>
          </div>

          <div className="row no-gutters">
            <div className="col-xs-12">
              <GalleryGeneral images={pictures}/>

              {/* <ul className="gallery-detail__list">
                {pictures.map((item) => (
                  <li key={item.id}><img className="img-responsive" src={`${process.env.REACT_APP_IMG_URL}/img/gallery/min/${item.picture}`} alt="La leyenda del dorado"/><span></span></li>
                ))}
              </ul> */}
            </div>
          </div>
        </div>
      </div>
    </LayoutLldd>
  )
}

export default GalleryDetail;
