import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import Layout from '../components/Layout';
import VideosMenu from '../components/VideosMenu';
import '../assets/styles/components/Videos.sass';

class VideosPrologue extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            data: [],
        }
    }
    
    componentDidMount() {
        
        fetch('https://la-leyenda.com/2018/api/videos/prologo')
        .then(res => res.json())
        .then(
        (res) => {
            this.setState({
                isLoaded: true,
                data: res
            });
        },
        
        (error) => {
            this.setState({
                isLoaded: true,
                error
            });
        }
        )

        document.getElementById('videos').classList.add('active')
        document.getElementById('videosPrologo').classList.add('active')
    }
  
    componentWillUnmount() {
        document.getElementById('videos').classList.remove('active')
        document.getElementById('videosPrologo').classList.remove('active')
    }
    
    render() {
        return(
            <Layout>
                <VideosMenu/>
                <div className="row videos-main">
                    <div className="container">
                        <div className="row no-gutters min-height">
                            <div className="col-xs-12">
                                <div className="custom">
                                    {this.state.data.map((item) => (
                                        <div>
                                            <strong>
                                                <span className="videos-main-title">
                                                {item.title_es}
                                                </span>
                                            </strong>
                                            <div className="embed-responsive embed-responsive-16by9">
                                                { ReactHtmlParser(item.video) }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default VideosPrologue;