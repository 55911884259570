import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";

import LayoutLldd from "../components/LayoutLldd";
import HomeMenu from "../components/HomeMenu";
import Banner from "../components/Banner";
import HorizontalFormContact from "../components/HorizontalFormContact";

import "../assets/styles/containers/HomePayment.sass";

const HomePayment = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`https://la-leyenda.com/2018/api/general-by-id/46`)
      .then((res) => res.json())
      .then((res) => {
        setData(res);
      });
  }, []);

  return (
    <LayoutLldd title={data.seo_title_es} description={data.seo_content_es}>
      <Banner />
      <HorizontalFormContact />
      <HomeMenu />
      <div className="row">
        <div className="container">
          <div className="row no-gutters home-payment">{ReactHtmlParser(data.content_es)}</div>
        </div>
      </div>
    </LayoutLldd>
  );
};

export default HomePayment;
