import React from 'react';
import { NavLink } from 'react-router-dom';
import '../assets/styles/components/HomeMenu.sass';

const HomeMenu = () => (
  <div className="row home-menu">
    <div className="container">
      <div className="row">
        <div className="col-xs-12">
          <ul className="home-menu__list">
            <li className="home-menu__list--item">
              <NavLink exact to="/">
                LA LEYENDA RESUMEN
              </NavLink>
            </li>
            <li className="home-menu__list--item">
              <NavLink to="/home/packages">
                PAQUETES & ASCENSOS
              </NavLink>
            </li>
            <li className="home-menu__list--item">
              <NavLink to="/home/prices">
                PRECIOS
              </NavLink>
            </li>
            <li className="home-menu__list--item">
              <NavLink to="/home/payment">
                PLAN DE PAGOS
              </NavLink>
            </li>
            <li className="home-menu__list--item">
              <NavLink to="/home/categories">
                CATEGORÍAS
              </NavLink>
            </li>
            <li className="home-menu__list--item">
              <NavLink to="/home/testimonials">
                TESTIMONIOS
              </NavLink>
            </li>
            <li className="home-menu__list--item">
              <NavLink to="/home/no-excuses">
                NO HAY EXCUSAS
              </NavLink>
            </li>
            <li className="home-menu__list--item">
              <NavLink to="/home/news">
                ÚLTIMAS NOTICIAS
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
)

export default HomeMenu;