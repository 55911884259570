import React, { useEffect, useState} from 'react';
import ReactHtmlParser from 'react-html-parser';

import LayoutLldd from '../components/LayoutLldd';
import BannerInternal from '../components/BannerInternal';

import '../assets/styles/containers/Jaguares.sass';

const Jaguares = () => {
  const [content, setContent] = useState({});
  const [jaguars, setJaguars] = useState([]);

  const modal = document.getElementById('gallery-modal');
  const modalContent = document.getElementById('modal-content');
  const arrowLeft = document.getElementById('arrow-left');
  const arrowRight = document.getElementById('arrow-right');
  let openId;

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/jaguares`)
    .then(res => res.json())
    .then(
      (res) => {
        setContent(res.content);
        setJaguars(res.jaguars);
      }
    )
  }, []);

  // KeyDown event
  document.addEventListener('keydown', (event) => {
    if (event.keyCode === 27) {
      handleClose();
    }
  });

  const handleOpen = (e, order) => {
    e.preventDefault();
    openId = order;
    const seleccionado = jaguars.find(item => item.order == order);
    handleModalInfo(seleccionado);
  }

  const handleClose = () => {
    if(modal) {
      modal.style.display = "none";
      modalContent.innerHTML = '';
    }
  }

  const handleModalInfo = (jaguar) => {
    let template = `
    <div class="jaguares-content">
      <div class="jaguares-content__foto">
        <img src="https://la-leyenda.com/en/img/jaguares/large/${jaguar.foto}" alt="la leyenda">
      </div>
      <div class="jaguares-content__info">
        <div class="jaguares-content__info--item">
          <p>Leyendas completadas</p>
          <strong>${jaguar.leyendas_completed}</strong>
        </div>
        <div class="jaguares-content__info--item">
          <p>Podios</p>
          <strong>${jaguar.podiums}</strong>
        </div>
        <div class="jaguares-content__info--item">
          <p>Mejor clasificación categoría</p>
          <strong>${jaguar.highest_cat_pos_es}</strong>
        </div>
        <div class="jaguares-content__info--item">
          <p>Mejor clasificación general</p>
          <strong>${jaguar.highest_gc}</strong>
        </div>
        <div class="jaguares-content__info--item">
          <p>Año de nacimiento</p>
          <strong>${jaguar.birthday}</strong>
        </div>
        <div class="jaguares-content__info--item">
          <p>Ciudad/Pueblo</p>
          <strong>${jaguar.home_town}</strong>
        </div>
      </div>
      <div class="jaguares-content__data">
        <div class="jaguares-content__data--number">${jaguar.order}</div>
        <div class="jaguares-content__data--flag">
          <img src="https://la-leyenda.com/en/img/flag/${jaguar.country}.png" alt="bandera del pais">
        </div>
        <div class="jaguares-content__data--name">
        ${jaguar.name} ${jaguar.surname}
        </div>
        <div class="jaguares-content__data--comment">
          <p>${jaguar.coment_es}</p>
        </div>
      </div>
    </div>
    `;

    modalContent.innerHTML = template;
    modal.style.display = "flex";
  }

  const handleCloseModalClickOutside = (event) => {
    const modalWraper =  document.getElementById('jaguares-modal__wraper');
    const modalNav =  document.getElementById('modal__nav');
    
    let isClickInsideWrapper = modalWraper.contains(event.target);
    let isClickInsideNav = modalNav.contains(event.target);
    if (!isClickInsideWrapper && !isClickInsideNav) {
      handleClose();
    }
  }

  // Next modal
  const handleNext = (e) => {
    e.preventDefault();
    if (openId == jaguars.length) {
      openId = 1;
    } else {
      openId = parseInt(openId) + 1;
    }

    const jaguar = jaguars.find(item => item.order == openId);
    handleModalInfo(jaguar);
  }

  // After modal
  const handleAfter = (e, id) => {
    e.preventDefault();
    if (openId == 1) {
      openId = jaguars.length;
    } else {
      openId = openId - 1;
    }

    const jaguar = jaguars.find(item => item.order == openId);
    handleModalInfo(jaguar);
  }

  console.log('content', content);

  return(
    <LayoutLldd
      title={content.seo_title_es}
      description={content.seo_content_es}
    >
      <BannerInternal image="https://la-leyenda.com/2018/en/img/banners_contenido/jaguares.jpg" />
      <div className="row jaguares">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-xs-12">
              { ReactHtmlParser(content.content_es) }
            </div>

            <div className="col-xs-12 mb20">
              <div className="jaguares-gallery">
                {jaguars.map(item => {
                  return (
                    <div kye={item.id} className="jaguares-gallery__item">
                      <div className="jaguares-gallery__item--info">
                        <div className="jaguares-info">
                          <span className="jaguares-info__name">{item.name}</span>
                          <span className="jaguares-info__surname">{item.surname}</span>
                        </div>
                        <div className="jaguares-country">
                          <img src={`${process.env.REACT_APP_IMG_URL}/img/flag/${item.country}.png`} alt="bandera del pais" />
                        </div>
                      </div>

                      <a className="jaguares-gallery__item--link" href="#" onClick={(e) => handleOpen(e, item.order)}>
                        <img src={`${process.env.REACT_APP_IMG_URL}/img/jaguares/${item.thumb}`} alt="la leyenda" />
                        <span>{item.order}</span>
                      </a>
                    </div>
                  )
                })}
              </div>
            </div>
        
          </div>
        </div>
      </div>

      <div class="jaguares-modal" id="gallery-modal" onClick={(event) => handleCloseModalClickOutside(event)}>
        <div id="jaguares-modal__wraper" class="jaguares-modal__wraper">
          <button type="button" class="gallery-modal__wraper--btn" onClick={() => handleClose()}>
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" class="svg-inline--fa fa-times fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
              <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
            </svg>
          </button>

          <div class="jaguares-modal__wraper--content" id="modal-content"></div>
        </div>

        <nav id="modal__nav" class="gallery-modal__nav">
          <ul>
            <li>
              <a href="" id="arrow-left" onClick={(event) => handleAfter(event)}>
                <img src="https://la-leyenda.com/img/arrow_left.png" />
              </a>
            </li>
            <li>
              <a href="" id="arrow-right" onClick={(event) => handleNext(event)}>
                <img src="https://la-leyenda.com/img/arrow_right.png" />
              </a>
            </li>
          </ul>
        </nav>
      </div>

    </LayoutLldd>
  )
}

export default Jaguares;
