import React from 'react';
import '../assets/styles/components/Gallery.sass';

class GalleryGeneral extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      imageIndex: null,
    }
  }

  galleryState(index) {
    this.setState({
      isOpen: true,
      imageIndex: index
    })
  }

  handleCloseModal() {
    this.setState({
      isOpen: false,
      imageIndex: null
    })
  }

  handleCloseModalClickOutside(e) {
    const modal__wraper =  document.getElementById('modal__wraper');
    const modal__nav =  document.getElementById('modal__nav');
    
    let isClickInsideWrapper = modal__wraper.contains(e.target);
    let isClickInsideNav = modal__nav.contains(e.target);
    if (!isClickInsideWrapper && !isClickInsideNav) {
      this.setState({
        isOpen: false,
        imageIndex: null
      })
    }
  }

  handleKeyDown = e => {
    if (e.keyCode === 27) {
      this.setState({
        isOpen: false,
        imageIndex: null
      })
    }
  };

  handleDownload() {
    const { images } = this.props;
    const { imageIndex } = this.state;
    const url = `${process.env.REACT_APP_IMG_URL}/img/gallery/large/${images[imageIndex].picture}`;
    let fileName = "la-leyenda.jpg";
    let xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function(){
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(this.response);
        var tag = document.createElement('a');
        tag.href = imageUrl;
        tag.download = fileName;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
    }
    xhr.send();
  }

  handleNext(e) {
    e.preventDefault();
    const { images } = this.props;
    const { imageIndex } = this.state;
    const url = `${process.env.REACT_APP_IMG_URL}/img/gallery/large/${images[(imageIndex + images.length + 1) % images.length].photo}`;
    this.setState({
      imageIndex: (imageIndex + images.length + 1) % images.length
    })
    document.getElementById('imageLarge').src = url;
  }

  handleAfter(e) {
    e.preventDefault();
    const { images } = this.props;
    const { imageIndex } = this.state;
    const url = `${process.env.REACT_APP_IMG_URL}/img/gallery/large/${images[(imageIndex + images.length - 1) % images.length].photo}`;
    this.setState({
      imageIndex: (imageIndex + images.length - 1) % images.length
    })
    document.getElementById('imageLarge').src = url;
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown, false);
  }

  render() {
    const { images } = this.props;
    const { isOpen, imageIndex } = this.state;

    return(
      <>
        <div className="gallery-container">
          {images.map((item, i) => (
            <div key={i} className="gallery-container__item">
              <button className="gallery-container__item--button" type="button" onClick={() => this.galleryState(i)}>
                <img className="gallery-container__item--img" src={`${process.env.REACT_APP_IMG_URL}/img/gallery/min/${item.picture}`} alt=""/>
                <span className="gallery-container__item--span"></span>
              </button>
            </div>
          ))}
        </div>

        {isOpen &&
          <div className="gallery-modal" onClick={(e) => this.handleCloseModalClickOutside(e)}>

            <div id="modal__wraper" className="gallery-modal__wraper">
              <button type="button" className="gallery-modal__wraper--btn" onClick={() => this.handleDownload()}>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-alt-circle-down" className="svg-inline--fa fa-arrow-alt-circle-down fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zM212 140v116h-70.9c-10.7 0-16.1 13-8.5 20.5l114.9 114.3c4.7 4.7 12.2 4.7 16.9 0l114.9-114.3c7.6-7.6 2.2-20.5-8.5-20.5H300V140c0-6.6-5.4-12-12-12h-64c-6.6 0-12 5.4-12 12z"></path>
                </svg>
              </button>

              <button className="gallery-modal__wraper--btn" onClick={() => this.handleCloseModal()}>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" className="svg-inline--fa fa-times fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                  <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
                </svg>
              </button>

              <div className="gallery-modal__wraper--content">
                <img id="imageLarge" src={`${process.env.REACT_APP_IMG_URL}/img/gallery/large/${images[imageIndex].picture}`} alt=""/>
              </div>
            </div>

            <nav id="modal__nav" className="gallery-modal__nav">
              <ul>
                <li>
                  <a href="#after" onClick={(e) => this.handleAfter(e)}>
                    <img src="https://la-leyenda.com/img/arrow_left.png" alt="Regresar una foto"/>
                  </a>
                </li>
                <li>
                  <a href="#next" onClick={(e) => this.handleNext(e)}>
                    <img src="https://la-leyenda.com/img/arrow_right.png"  alt="Adelantar una foto"/>
                  </a>
                </li>
              </ul>
            </nav>

          </div>
        }

      </>
    )
  }
}

export default GalleryGeneral;
