import React from "react";
import { NavLink } from "react-router-dom";
import "../assets/styles/components/StageMenu.sass";

const VideosMainMenu = () => (
  <div className="row stage-menu">
    <div className="container">
      <div className="row">
        <div className="col-xs-12">
          <ul className="stage-menu__list">
            <li className="stage-menu__list--item">
              <NavLink id="videosPrologo" to="/videos/2016">
                2016
              </NavLink>
            </li>
            <li className="stage-menu__list--item">
              <NavLink id="videosEtapa1" to="/videos/2017">
                2017
              </NavLink>
            </li>
            <li className="stage-menu__list--item">
              <NavLink id="videosEtapa2" to="/videos/2018">
                2018
              </NavLink>
            </li>
            <li className="stage-menu__list--item">
              <NavLink id="videosEtapa3" to="/videos/2019">
                2019
              </NavLink>
            </li>
            <li className="stage-menu__list--item">
              <NavLink id="videosEtapa3" to="/videos/2022">
                2022
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
);

export default VideosMainMenu;
