import React from 'react';
import '../assets/styles/components/Table.sass';

const Table = (props) => {
  const { racers } = props
  return(
    <div className="row">
      <div className="hidden-xs col-sm-12">
        <section className="table-results">
          <div className="table-results__title table-results__pos-category">CLASIFICACIÓN CATEGORÍA</div>
          <div className="table-results__title table-results__pos-overall">CLASIFICACIÓN GENERAL</div>
          <div className="table-results__title table-results__category">CATEGORÍA</div>
          <div className="table-results__title table-results__number">NÚMERO CORREDOR</div>
          <div className="table-results__title table-results__racer">NOMBRE DEL CORREDOR</div>
          <div className="table-results__title table-results__team">NOMBRE DEL EQUIPO</div>
          <div className="table-results__title table-results__time">TIEMPO TOTAL</div>
          
          {racers.map( racer => (
            <>
            <div className="table-results__pos-category racer__red">{racer.category_rank}</div>
            <div className="table-results__pos-overall">{racer.overall_rank}</div>
            <div className="table-results__category">{racer.categoria_es}</div>
            <div className="table-results__number">
              <p>{racer.numero_corredor_1}</p>
              <p>{racer.numero_corredor_2}</p>
            </div>
            <div className="table-results__racer">
              <p><img src={`https://la-leyenda.com/2018/en/img/banderas/${racer.nacionalidad_corredor_1_en}.png`} alt=""/> {racer.nombre_corredor_1}</p>
              <p><img src={`https://la-leyenda.com/2018/en/img/banderas/${racer.nacionalidad_corredor_2_en}.png`} alt=""/> {racer.nombre_corredor_2}</p>
            </div>
            <div className="table-results__team">{racer.equipo}</div>
            <div className="table-results__time">
              <p>{racer.tiempo}</p>
              {racer.diferencia !== '-' ?
              <p className="racer__red">+{racer.diferencia}</p> :
              <p className="racer__red">-</p>
            }
          </div>
          </>
          ))}
          
        </section>
        
      </div>
      <div className="visible-xs col-xs-12">
        <section className="table-results-mobile">
          {racers.map( (racer, i) => (
            <div className="table-results-mobile__item" key={i}>
              <p><b>Clasificación categoría:</b> {racer.category_rank}</p>
              <p><b>Clasificación general:</b> {racer.overall_rank}</p>
              <p><b>Categoría:</b> {racer.categoria_es}</p>
              <p><b>Nombre del equipo:</b> {racer.equipo}</p>
              <p><b>Corredores:</b></p>
              <span className="table-results-mobile__item--racer">{racer.numero_corredor_1} <img src={`https://la-leyenda.com/2018/en/img/banderas/${racer.nacionalidad_corredor_1_en}.png`} alt=""/> {racer.nombre_corredor_1}</span>
              <span className="table-results-mobile__item--racer">{racer.numero_corredor_2} <img src={`https://la-leyenda.com/2018/en/img/banderas/${racer.nacionalidad_corredor_2_en}.png`} alt=""/> {racer.nombre_corredor_2}</span>
              <p>
                <b>Tiempo total: </b> 
                {racer.tiempo} / 
                <span className="racer__red">
                  {racer.diferencia !== '-' ? ` +${racer.diferencia}` : '-'}
                </span>
              </p>
            </div>
          ))}
        </section>
      </div>
    </div>
  )
}

export default Table;