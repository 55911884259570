import React, { useEffect } from "react";

import LayoutLldd from "../components/LayoutLldd";
import BannerInternal from "../components/BannerInternal";
import setInputValue from "../hooks/useInputValue";

import "../assets/styles/containers/ContactUs.sass";

const ContactUs = () => {
  let formButton = document.getElementById("button-send");
  let formInputs = document.getElementById("main-form");
  const name = setInputValue("");
  const email = setInputValue("");
  const phone = setInputValue("");
  const message = setInputValue("");

  useEffect(() => {
    formButton = document.getElementById("button-send");
    formInputs = document.getElementById("main-form");
  }, []);

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    if (name.value == "" || email.value == "" || message.value == "") {
      formButton.innerHTML = "INGRESE INFORMACIÓN";
      const inputs = formInputs.elements;

      if (name.value == "") inputs["name"].classList.add("btn-fill");
      if (email.value == "") inputs["email"].classList.add("btn-fill");
      if (message.value == "") inputs["message"].classList.add("btn-fill");
      formButton.classList.add("btn-fill");
      validate();
    } else {
      sendForm();
    }
  };

  const sendForm = async () => {
    const formData = new FormData();
    formData.append("name", name.value);
    formData.append("email", email.value);
    formData.append("phone", phone.value);
    formData.append("mensaje", message.value);
    formData.append("languaje", "es");
    formData.append("section", "contactHome");

    fetch(`${process.env.REACT_APP_URL}/send-contact-us`, {
      method: "POST",
      body: formData,
    });

    const dataEmail = {
      name: name.value,
      email: email.value,
      message: message.value,
      section: "contactHome",
    };

    fetch(`${process.env.REACT_APP_SEND_EMAIL_HORIZONTAL}/main-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataEmail),
    }).catch((error) => {
      console.log(error);
    });

    gracias();
  };

  const gracias = () => {
    formButton.innerHTML = "GRACIAS";
    formButton.classList.add("btn-thanks");
    resetForm();
  };

  const resetForm = () => {
    setTimeout(() => {
      const inputs = formInputs.elements;

      formButton.innerHTML = "ENVIAR";
      formButton.classList.remove("btn-thanks");
      inputs["name"].value = "";
      inputs["email"].value = "";
      inputs["phone"].value = "";
      inputs["message"].value = "";
    }, 3000);
  };

  const validate = () => {
    setTimeout(() => {
      const inputs = formInputs.elements;

      formButton.innerHTML = "ENVIAR";
      formButton.classList.remove("btn-fill");
      inputs["name"].classList.remove("btn-fill");
      inputs["email"].classList.remove("btn-fill");
      inputs["message"].classList.remove("btn-fill");
    }, 3000);
  };

  return (
    <LayoutLldd>
      <BannerInternal image="https://la-leyenda.com/img/banners_contenido/contact-us.jpg" />
      <div className="row whats-included">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-xs-12 text-center">
              <h1 className="whats-included__title">CONTÁCTANOS</h1>
            </div>
          </div>

          <div className="row no-gutters">
            <div className="col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 col-lg-8 col-lg-offset-2 text-justify">
              <p>
                Por favor complete el siguiente formulario o comuníquese con nosotros por correo electrónico a{" "}
                <a href="mailto: info@la-leyenda.com" className="black-link">
                  info@la-leyenda.com
                </a>
              </p>
              <form id="main-form" className="contact-form" onSubmit={(e) => handleOnSubmit(e)}>
                <input type="text" name="name" placeholder="NOMBRE*" {...name} />
                <input type="email" name="email" placeholder="EMAIL*" {...email} />
                <input type="text" name="phone" placeholder="TELÉFONO" {...phone} />
                <textarea name="message" placeholder="MENSAJE*" cols="30" rows="10" {...message}></textarea>
                <button id="button-send" type="submit">
                  ENVIAR
                </button>
              </form>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <h2 className="text-center">EL EQUIPO</h2>
              <ul className="team">
                <li>
                  <img className="img-responsive" src="https://la-leyenda.com/img/team/brian.jpg" alt="" />
                  <div>
                    <h3>
                      FUNDADOR <br /> DE LA CARRERA
                    </h3>
                    <p>Brian Murphy </p>
                    <p>brian@la-leyenda.com</p>
                  </div>
                </li>
                <li>
                  <img className="img-responsive" src="https://la-leyenda.com/img/team/rohan.jpg" alt="" />
                  <div>
                    <h3>
                      DIRECTOR <br /> DE LA CARRERA
                    </h3>
                    <p>Rohan Ayres </p>
                    <p>rohan@la-leyenda.com</p>
                  </div>
                </li>
                <li>
                  <img className="img-responsive" src="https://la-leyenda.com/img/team/dave.jpg" alt="" />
                  <div>
                    <h3>
                      GERENTE DE <br /> COMUNICACIONES
                    </h3>
                    <p>Dave Procter</p>
                    <p>dave@la-leyenda.com</p>
                  </div>
                </li>
                <li>
                  <img className="img-responsive" src="https://la-leyenda.com/img/team/diego.jpg" alt="" />
                  <div>
                    <h3>
                      GERENTE <br /> GENERAL
                    </h3>
                    <p>Diego Velasquez </p>
                    <p>diego@la-leyenda.com</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </LayoutLldd>
  );
};

export default ContactUs;
