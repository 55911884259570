import React from 'react';
import '../assets/styles/components/Table.sass';

const TableIndividual = (props) => {
  const { racers } = props
  return(
    <div className="row">
      <div className="hidden-xs col-sm-12">
        <section className="table-results">
          <div className="table-results__title table-results__pos-category">CLASIFICACIÓN CATEGORÍA</div>
          <div className="table-results__title table-results__pos-overall">CLASIFICACIÓN GENERAL</div>
          <div className="table-results__title table-results__category">CATEGORÍA</div>
          <div className="table-results__title table-results__number">NÚMERO CORREDOR</div>
          <div className="table-results__title table-results__racer">NOMBRE DEL CORREDOR</div>
          <div className="table-results__title table-results__team">NOMBRE DEL EQUIPO</div>
          <div className="table-results__title table-results__time">TIEMPO TOTAL</div>
          
          {racers.map( (racer, i) => (
            <>
              <div className="table-results__pos-category racer__red">-</div>
              <div className="table-results__pos-overall">-</div>
              <div className="table-results__category">{racer.categoria_es}</div>
              <div className="table-results__number">
                <p>{racer.numero}</p>
              </div>
              <div className="table-results__racer">
                <p><img src={`https://la-leyenda.com/2018/en/img/banderas/${racer.nacionalidad}.png`} alt=""/> {racer.nombre}</p>
              </div>
              <div className="table-results__team">{racer.equipo}</div>
              <div className="table-results__time">
                <p>{racer.tiempo}</p>
              </div>
            </>
          ))}
          
        </section>
        
      </div>
      <div className="visible-xs col-xs-12">
        <section className="table-results-mobile">
          {racers.map( (racer, i) => (
            <div className="table-results-mobile__item" key={i}>
              <p><b>Clasificación categoría:</b> -</p>
              <p><b>Clasificación general:</b> -</p>
              <p><b>Categoría:</b> {racer.categoria_es}</p>
              <p><b>Nombre del equipo:</b> {racer.equipo}</p>
              <p><b>Corredor:</b></p>
              <span className="table-results-mobile__item--racer">{racer.numero_corredor_1} <img src={`https://la-leyenda.com/2018/en/img/banderas/${racer.nacionalidad}.png`} alt=""/> {racer.nombre}</span>
              <p>
                <b>Tiempo total: </b> 
                {racer.tiempo}
              </p>
            </div>
          ))}
        </section>
      </div>
    </div>
  )
}

export default TableIndividual;