import React, { useEffect, useState} from 'react';
import ReactHtmlParser from 'react-html-parser';

import LayoutLldd from '../components/LayoutLldd';
import BannerInternal from '../components/BannerInternal';

import '../assets/styles/containers/TipsTricks.sass';

const TipsTricks = () => {
  const [intro, setIntro] = useState({});
  const [before, setBefore] = useState({});
  const [during, setDuring] = useState({});
  const [after, setAfter] = useState({});

  useEffect(() => {
    fetch(`https://la-leyenda.com/2018/api/general/tips-and-tricks`)
    .then(res => res.json())
    .then(
      (res) => {
        setIntro(res.intro);
        setBefore(res.before);
        setDuring(res.during);
        setAfter(res.after);
      }
      )
    }, []);

  return(
    <LayoutLldd
      title={intro.seo_title_es}
      description={intro.seo_content_es}
    >
      <BannerInternal image="https://la-leyenda.com/img/banners_contenido/tips_and_tricks.jpg" />
      <div className="row tips-tricks">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-xs-12">
              { ReactHtmlParser(intro.content_es) }
            </div>
          </div>

          <div className="row no-gutters">

            <div className="col-xs-12">
              <ul className="tips-tricks-nav nav-tabs" id="tabs_home" role="tablist">
                <li role="presentation" className="active"><a href="#altiplano" aria-controls="altiplano" role="tab" data-toggle="tab">Antes de la carrera</a></li>
                <li role="presentation"><a href="#vip" aria-controls="vip" role="tab" data-toggle="tab">Durante la carrera</a></li>
                <li role="presentation"><a href="#ascensos" aria-controls="ascensos" role="tab" data-toggle="tab">Recuperación y fuera de la bici</a></li>
              </ul>
            </div>
            <div className="col-xs-12 no-padding">
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane fade in active" id="altiplano">
                  { ReactHtmlParser(before.content_es) }
                </div>
                <div role="tabpanel" className="tab-pane fade" id="vip">
                  { ReactHtmlParser(during.content_es) }
                </div>
                <div role="tabpanel" className="tab-pane fade" id="ascensos">
                  { ReactHtmlParser(after.content_es) }
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </LayoutLldd>
  )
}

export default TipsTricks;
